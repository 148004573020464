<template>
  <dash-page-new
      :title="server"
      subtitle="General overview"
      root="Servers"
      :root-location="dashboardLink('servers')"
      icon="mdi-server"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >
    <template #header_action>
      <ws-button
          @click="openEditServer"
          label="Edit"
          class="mr-2"
      />
      <ws-button
          v-if="isPrivateCloud === true"
          @click="openFileRelocation"
          label="File relocation"
      />
    </template>

    <template #default>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Server Details</h3>
        <h4 class="mt-3"></h4>
      </v-sheet>

      <v-sheet
          :color="wsDARKER"
          class="pa-6 wsRoundedHalf mt-6"
          dark
      >
        <div v-if="Object.keys(viewData).length === 0">
          <code>Server is not processed by polling system</code>
        </div>

        <div v-for="(value,key) in viewData" :key="key">
          <code> <b>{{ key }}</b> : {{ value }} </code>
        </div>
      </v-sheet>

      <ws-dialog
          v-model="displayEditServerDialog"
          @save="editServer"
          title="Edit server"
          save-text="Save"
          cancel-text="Cancel"
      >
        <ws-text-field
            v-model="entityData.alias"
            label="Server Name"
            placeholder="cloud.cs1.westudy.ua"
        />
        <ws-select
            class="mt-6"
            v-model="entityData.dev"
            :items="devTypes"
            label="Is dev"
            placeholder="If application located on local machine"
        />
        <ws-select
            v-model="entityData.type"
            :items="serverTypes"
            label="Server Type"
            placeholder="Choose server type"
        />
        <ws-text-field
            v-model="entityData.url"
            label="Url"
            placeholder="cloud.cs1.westudy.ua"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.ip"
            label="Dedicated IP Address"
            placeholder="123.123.421.242"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.port"
            label="Port (Usually uses for local development, use subdomains on prod)"
            placeholder="1234"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_cores"
            label="CPU Cores"
            number=1
            placeholder="24"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_critical_temp"
            label="Critical CPU temperature"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_critical_avg"
            label="Critical CPU AVG (Usually same as core count)"
            placeholder="24"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.hdd_critical_load"
            label="Critical HDD load"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.ram_critical_load"
            label="Critical RAM load"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.token"
            label="Health token"
            placeholder="G0394JG3049GJ3049GJ0432GJ304HJ35"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.business_alias"
            label="To whom belongs"
            placeholder="coco_style"
        />
      </ws-dialog>

      <ws-dialog
          v-model="displayFileRelocationDialog"
          @save="executeFileRelocation"
          title="File relocation"
          save-text="Save"
          cancel-text="Cancel"
      >
        <ws-select
            v-model="entityData.selectedCloudServers"
            :items="cloudServers"
            label="Source servers"
            placeholder="Select, from where to take"
            multiple
        />
      </ws-dialog>
    </template>
  </dash-page-new>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "serverDetails",
  props: {
    server: {
      type: String,
      default: ''
    }
  },
  // props: ['server', 'asd'],
  data() {
    return {
      privateBusinessAlias: null,
      entityData: {},
      serverData: {},
      viewData: {},
      cloudServers: [],
      selectedCloudServers: [],
      isPrivateCloud: false,
      displayEditServerDialog: false,
      displayFileRelocationDialog: false,
      serverTypes: [
        {text: 'api', value: 'api'},
        {text: 'cloud', value: 'cloud'},
        {text: 'health', value: 'health'},
      ],
      devTypes: [
        {text: 'Yes', value: 1},
        {text: 'No', value: 0},
      ],
    }
  },
  methods: {
    ...mapActions('servers', [
      'GET_SERVERS_HEALTH',
      'GET_SERVER_CONFIG',
      'EDIT_SERVER',
      'GET_NON_BUSINESS_CLOUD_SERVERS',
      'EXECUTE_FILE_RELOCATION',
    ]),

    async openEditServer() {
      let data = await this.GET_SERVER_CONFIG(this.server);
      if (!data) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.entityData = data;
      this.displayEditServerDialog = true
    },

    async editServer() {
      let result = await this.EDIT_SERVER(this.entityData);
      if (!result) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      if (this.serverData.alias !== this.entityData.alias) {
        await this.$router.push('/dashboard/servers');
      }

      this.displayEditServerDialog = false;
    },

    async openFileRelocation() {
      let servers = await this.GET_NON_BUSINESS_CLOUD_SERVERS();
      if (!servers) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      Object.keys(servers).forEach(server => {
        this.cloudServers.push({
          text: servers[server].alias,
          value: servers[server].alias,
        });
      });

      this.displayFileRelocationDialog = true
    },

    async executeFileRelocation() {
      let list = [];
      Object.keys(this.cloudServers).forEach(server => {
        list.push(this.cloudServers[server].value);
      });

      let result = await this.EXECUTE_FILE_RELOCATION({
        server_alias: this.server,
        business_alias: 'coco_style',//this.privateBusinessAlias,
        source_servers: list,
      });
      if (!result) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.displayEditServerDialog = false;
    },

    async initData() {
      let data = await this.GET_SERVER_CONFIG(this.server);
      if (!data) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.serverData = data;
      this.viewData = {
        'Is dev': !!data.is_dev,
        'Type': data.type,
        'Url': data.url,
        'Is private': data.private,
        'Business alias': data.business_alias,
      };

      if (data.private) {
        this.isPrivateCloud = true;
      }
    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>