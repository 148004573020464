const actions = {
    async GET_LOG_FILES({dispatch}, serverAlias) {
        return await dispatch('ajax/SEND_NEW', {
            route: `mtc/journal/list/` + serverAlias,
            method: 'GET',
        }, {root: true});
    },

    async GET_LOG_TEXT({dispatch}, data) {
        return await dispatch('ajax/SEND_NEW', {
            route: `mtc/journal/get/` + data.alias + `/` + data.file_name + `?search_in_file=` + data.search_in_file + `&start_line=` + data.start_line + `&end_line=` + data.end_line,
            method: 'GET',
        }, {root: true});
    },
}

export default {
    namespaced: true,
    actions
}
