var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("width : " + _vm.width))},[(_vm.label)?_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('h5',{staticStyle:{"color":"#4F5A6E","font-size":"13px","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.tooltip)?_c('ws-tooltip',{attrs:{"text":_vm.tooltip}},[_c('v-icon',{attrs:{"color":_vm.wsDARKLIGHT}},[_vm._v("mdi-information-outline")])],1):_vm._e()],1):_vm._e(),(!_vm.autocomplete)?_c('v-select',{staticClass:"textStyle mt-0",class:{
        activeText : _vm.focused && !_vm.error,
        notActive : !_vm.focused && !_vm.error,
        errorStyle : _vm.error
      },attrs:{"items":_vm.items,"placeholder":!_vm.text ? _vm.placeholder : '',"color":_vm.wsACCENT,"type":_vm.type,"dense":"","outlined":"","hide-details":_vm.hideDetails,"error":_vm.error,"error-messages":_vm.errorMessages,"height":_vm.height,"counter":_vm.counter,"clearable":_vm.clearable,"prepend-inner-icon":_vm.prependInnerIcon,"disabled":_vm.disabled,"multiple":_vm.multiple,"background-color":_vm.backgroundColor || null},on:{"keypress":function($event){return _vm.handleKeyPress( $event,_vm.text )},"focusin":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false ; _vm.$emit('change',_vm.text)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [(item.icon)?_c('v-icon',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(item.icon))]):_vm._e(),_c('h5',[_vm._v(_vm._s(item.text))])]}}],null,false,2352596587),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('v-autocomplete',{staticClass:"textStyle mt-0",class:{
        activeText : _vm.focused && !_vm.error,
        notActive : !_vm.focused && !_vm.error,
        errorStyle : _vm.error
      },attrs:{"items":_vm.items,"placeholder":_vm.placeholder,"color":_vm.wsACCENT,"type":_vm.type,"dense":"","outlined":"","hide-details":!_vm.counter,"error":_vm.error,"height":_vm.height,"counter":_vm.counter,"clearable":_vm.clearable,"multiple":_vm.multiple},on:{"keypress":function($event){return _vm.handleKeyPress( $event,_vm.text )},"focusin":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false ; _vm.$emit('change',_vm.text)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }