<template>
  <v-sheet height="100vh" color="transparent"  >

    <v-row class="no-gutters flex-wrap flex-column fill-height " >
      <v-col cols="auto" class="shrink"  >
        <v-sheet color="transparent" height="60"  class="d-flex   align-center" :class="sideMenu ? 'justify-space-between' : 'justify-center'" >
          <div style="width: 100%" v-if="sideMenu" >
            <router-link :to="localeLink('dashboard')" target="_blank">
              <div>
                <img id="dashboard_logo"  height="40px" class="mt-3 pl-6" alt="WeStudy" src="@/assets/logos/westudy_color.png" />
              </div>
              <div>
              </div>
            </router-link>
          </div>

          <div class="">
            <v-btn :color="wsACCENT"
                   :class="[{'mr-5' : sideMenu}]"
                   @click="$store.state.dashboard.sideMenu = !$store.state.dashboard.sideMenu"  icon  >
              <v-icon  >mdi-arrow-collapse-{{ sideMenu ? 'left' : 'right' }}</v-icon>
            </v-btn>
          </div>

        </v-sheet>
      </v-col>
      <v-col cols="auto" class="grow overflow-y-auto " style="height: 100px"  >
        <menu-elements admin />
      </v-col>

    </v-row>



    </v-sheet>

</template>

<script>
import {mapState} from 'vuex';
import menuElements from "@/components/dashboard/UI/sidebar/menuElements";
export default {
  name: "sideBar",
  components : {
    menuElements
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('dashboard',['sideMenu'])
  }


}
</script>

<style scoped>

.menu_header {
  height: 70px;
  transition: width 0.3s ease-out;
}

.menu {
  position:fixed;
  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: width 0.3s ease-out;
  overflow-y: auto;
}
.menuActive {
  width:250px !important;
  transition: width 0.3s ease-out;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>