import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import factory from '@/factory';

import UUID from "vue-uuid";
import notifications from "@/mixins/notifications";
import checkPermissions from "@/mixins/auth/checkPermissions";
import formatTime from "@/mixins/formatTime";
import templatesConfig from "@/mixins/templatesConfig";
import { VueMasonryPlugin } from "vue-masonry";
import VueApexCharts from 'vue-apexcharts'
import VueResponsiveDash from 'vue-responsive-dash'
import device from "vue-device-detector"
import VueHtml2Canvas from 'vue-html2canvas';
import VueShepherd from 'vue-shepherd';
import VueInputAutowidth from 'vue-input-autowidth'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import PortalVue from 'portal-vue';
import socketMixin from "@/mixins/socketMixin";

// Register the PortalVue component as a plugin
Vue.use(PortalVue);

Vue.use(VueTextareaAutogrowDirective)
Vue.use(VueInputAutowidth)

// global register
Vue.use(VueShepherd);
Vue.use(VueHtml2Canvas);
Vue.use(device)
Vue.use(VueApexCharts)
Vue.use(UUID);
Vue.use(VueResponsiveDash)
Vue.use(VueMasonryPlugin);


Vue.mixin(checkPermissions)
Vue.mixin(formatTime)
Vue.mixin(notifications)
Vue.mixin(templatesConfig)
Vue.mixin(socketMixin)

Vue.directive('windowResize', {
  inserted: function(el, binding) {
    const onResizeCallback = binding.value;
    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      onResizeCallback({ width, height });
    })
  }
});

Vue.config.productionTip = false

const { ajax_new,router,store,socket ,i18n } = factory

Vue.use(socket)

new Vue({
  vuetify,
  ajax_new,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
