<template>
    <v-sheet color="transparent" class="relative" >

        <v-sheet color="transparent" :key="'content' + refresh"  >

          <div v-for="(section, i) in content" :key="'section' + i +refresh" >

            <viewer-section-html
                :no-padding="noPadding"
                v-if="section.isHtml"
                :section="section"
                :key="'html_' + i "
                :font-size="fontSize"
            />

            <viewer-section-file
                v-if="section.isFile"
                :student="student"
                :course-id="courseId"
                :section="section"
                :key="'file_' + i "
                :isPublic="isPublic"
            />

            <viewer-section-video
                v-if="section.isVideo"
                :section="section"
                :student="student"
                :course-id="courseId"
                :test-id="testId"
                :index="i"
                :key="'video_' + courseId + i + refresh "
                :isPublic="isPublic"
            />

            <viewer-section-image
                v-if="section.isImage"
                :section="section"
                :key="'image_' + courseId "
                :allow-download-image="allowDownloadImage"
            />

          </div>

        </v-sheet>

    </v-sheet>
</template>

<script>

import viewerSectionFile  from "@/components/UI/textViewer/viewerSectionFile";
import viewerSectionHtml  from "@/components/UI/textViewer/viewerSectionHtml";
import viewerSectionImage from "@/components/UI/textViewer/viewerSectionImage";
import viewerSectionVideo from "@/components/UI/textViewer/viewerSectionVideo";

export default {
  name: "wsTextEditor",
  props : {
    value : {
      type : Array
    },
    html : {
      type : String
    },
    student : {
      type : Boolean,
      default : false,
    },
    courseId : {
      type : String,
    },
    noPadding : {
      type : Boolean,
      default : false,
    },
    testId : {
      type : String,
    },
    isPublic : {
      type : Boolean,
      default : false,
    },
    allowDownloadImage : {
      type : Boolean,
      default : false,
    },
    fontSize : {
      type : String,
    }
  },
  components : {
    viewerSectionFile,
    viewerSectionHtml,
    viewerSectionImage,
    viewerSectionVideo,
  },
  computed: {
    htmlSection() {
      return {
        isHtml : true,
        content : ''
      }
    },
  },
  data() {
    return {
      content : [],
      refresh : 0,
      displayAddButtons : false,
      edit : false,
      editDropDownOn : false,
    }
  },
  watch : {
    value() {
      if ( this.value !== this.content ) {
        this.refresh ++ ;
        this.initContent()
      }
    },
  },
  methods : {

    initContent() {
      if ( this.value ) {
        this.content = JSON.parse(JSON.stringify(this.value))
        if ( this.content.length === 0 && !this.html ) {
          this.content.push(JSON.parse(JSON.stringify(this.htmlSection)))
        }
        if ( this.content.length === 0 && this.html ) {
          let htmlSection = JSON.parse(JSON.stringify(this.htmlSection))
          htmlSection.text = this.html
          this.content.push(htmlSection)
        }
      }
    }

  },
  mounted() {
    this.initContent()
  }
}
</script>

<style scoped>

.addButtonsStyle {
  z-index:999;
  position: absolute;
  bottom : -30px ;
  right: 0;
  left: 0 ;
  margin-left : auto;
  margin-right: auto
}
.relative {
  position : relative
}

[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
}
</style>