import Vue from "vue"
import Vuex from "vuex"
import modules from './modules';

Vue.use(Vuex);

export default function getStore($ajax_new, baseUrl, hostname , second_hostname )  {
    let store = new Vuex.Store({
        namespaced : true,
        modules,
        state : {
            $ajax_new,
            LOADING : false,
            $socket : null,
            $sockets : null,
            project : 'fine',
            MAINTENANCE_ON : false,
            filesPublic : baseUrl + '/storage/',
            ajaxRoute : baseUrl,
            hostname : hostname,
            second_hostname : second_hostname,
            appUrl : "",
            FRONT_LOCATION : '',

            refresh: false,
            showLoginMenu: false,

            notification_text : '',
            notification_icon : '',
            notification : false,

            domain : false,
            secondaryHost : false,

            shiftDown : false,
            languages : ['ua','en','ru'],

            colors : {
                wsACCENT      : "#567186",
                wsDARK        : "#567186",
                wsDARKER      : "#283E4F",
                wsDARKLIGHT   : "#94b3c7",
                wsBACKGROUND  : "#DEE8EE",
                wsLIGHTACCENT : "#D7E5F0",
                wsLIGHTCARD   : "#F2F8FB",
                wsSUCCESS     : "#B3DF93",
                wsLIGHTSUCCESS: "#DDF5CD",
                wsATTENTION   : "#ED8A58",
                wsWARNING   : "#fe4b4b",
                wsDASHBACKGROUND  : "#DCE8EF",
            },

        },
        mutations : {
            toggleLoginMenu(state) {
                state.showLoginMenu=!state.showLoginMenu;
            },
            REFRESH(state) {
                state.refresh = true;
                setTimeout(()=>{
                    state.refresh = false;
                },100)
            },
            TOOGLE_NOTIFICATION(state,payload) {
                state.showNotification = !state.showNotification;
                if (payload) {
                    state.notificationMessage = payload.message
                }
            }
        }

    })
    if (module.hot) {
        // принимаем действия и мутации как горячие модули
        module.hot.accept([ './modules/'], () => {

            // меняем местами новые модули и мутации
            store.hotUpdate({
                mutations: store.mutations,
                state  :store.state,
                modules: modules
            })
        })
    }
    return store
}