<template>
  <v-sheet
      height="100vh"
      id="dashPage"
      ref="dashPage"
      class="wsRoundedLightLeft"
      style="box-shadow: -8px 0 20px rgba(86, 113, 134, 0.05); position: relative"
      :class="[{'overflow-y-auto' : !SM} ]"
      @scroll="setScrollPosition"
  >
    <v-row class="no-gutters flex-wrap flex-column fill-height">
      <!-- PreHeader -->
      <v-col cols="auto" class="shrink" >
        <v-sheet width="100%" dark :color="preheaderColor || wsDARKLIGHT">
          <div class="d-flex">
            <slot name="pre_header.wide"></slot>
            <div class="mx-auto">
              <slot name="pre_header"></slot>
            </div>
          </div>
        </v-sheet>
      </v-col>

      <v-col cols="auto" class="grow" style="height: 100px"  >
        <div class="fill-height"
             :class="[
                 { 'px-10' : !SM && !noPadding } ,
                 { 'px-4'  : SM && !noPadding } ,
                 { 'py-5' : !noPadding}
             ]"
        >
          <div class="d-flex fill-height">
            <div :style="`width: ${width}`" class="mx-auto fill-height">

              <v-row class="no-gutters flex-wrap flex-column fill-height">
                <!-- Header : Title and Subtitile -->
                <v-col v-if="!noHeader" cols="auto" class="shrink" >
                  <div class=" align-center justify-space-between"
                       :class="[{'d-flex' : !SM}]"
                  >
                    <!-- Header : Title and Subtitile -->
                    <div>
                      <div  :class="[{'mb-2' : !noPadding}]">
                        <!-- Header : Title and Subtitile -->
                        <h5 :class="[{ 'text-center' : SM , 'd-flex' : !SM }]" >
                          <router-link
                              :is="!rootLocation ? 'span' : 'router-link'"
                              :to="rootLocation"
                              class="noUnderline hoverUnderline"  :style="`color : ${wsACCENT}`">
                            <span :class="!SM ? 'mr-1' : null" >{{  root  }} </span>
                          </router-link>

                          <span v-if="subtitle">  {{ ' / ' + subtitle }}</span>
                        </h5>
                      </div>
                      <div class="d-flex align-center" :class="SM ? 'justify-center' : null">
                        <v-btn v-if="icon || headerRoute"
                               :to="headerRoute ? headerRoute : ''"
                               class="mr-2 ml-n2"
                               icon>
                          <v-icon    :color="wsACCENT">{{ icon ? icon : 'mdi-chevron-left' }}</v-icon>
                        </v-btn>
                        <h5 class="d-flex" v-if="title" :style="!SM ? 'font-size: 18px' : ''"> {{ title }}
                          <slot name="header_title_edit"></slot>
                        </h5>

                      </div>
                    </div>


                    <!-- Header : Action -->

                    <div class="d-flex align-center">

                       <v-sheet v-if="filters.length > 0" width="100%">
                          <wsSearchFilters class="mr-4" @input="$emit('filter',$event)" v-model="filtersData" :items="filters" />
                      </v-sheet>
                      <slot  name="header_action"></slot>
                      <slot v-if="!SM" name="header_action.desktop"></slot>
                    </div>

                    <slot v-if="SM" name="header_action.mobile"></slot>


                  </div>

                </v-col>

                <v-col cols="auto" class="grow" style="height: 50px" >

                  <div class="d-flex flex-wrap">
                    <v-chip v-if="clearFiltersCondition" class="pr-1 pl-5 mt-2 mr-2" :color="wsACCENT" dark >
                      <h5>{{  $t('ClearFilters')  }}</h5>
                      <v-btn @click="filtersData = {}; $emit('filter',{})" class="ml-2" icon small>
                        <v-icon >mdi-close-circle</v-icon>
                      </v-btn>

                    </v-chip>

                    <template   v-for="(data,key) in filtersData">
                      <v-chip
                          v-if="data !== null && data !== undefined && !Array.isArray(data) "
                          :key="key"
                          class="pr-1 pl-5 mt-2 mr-2"
                          outlined
                          :color="wsACCENT" dark >
                        <h5 v-if=" key !== 'search'">{{  getFilterText(key , data)  }}</h5>
                        <h5 v-else> {{$t('Search')}} : "{{ data }}"</h5>
                        <v-btn @click="eraseFilter(key)" :color="wsACCENT" class="ml-2" icon small>
                          <v-icon >mdi-close-circle</v-icon>
                        </v-btn>

                      </v-chip>
                      <template v-else-if="Array.isArray(data) " >
                        <v-chip v-for="(value, j ) in data"
                            :key="key + j"
                            class="pr-1 pl-5 mt-2 mr-2"
                            outlined
                            :color="wsACCENT" dark >
                          <h5>{{  getFilterText(key , value )  }}</h5>
                          <v-btn @click="eraseFilter(key , j)" :color="wsACCENT" class="ml-2" icon small>
                            <v-icon >mdi-close-circle</v-icon>
                          </v-btn>

                        </v-chip>
                      </template>

                    </template>

                  </div>

                  <slot v-if="loading" name="loading">
                    <v-sheet height="350" class="d-flex align-center justify-center">
                      <v-progress-circular size="82" :color="wsACCENT" indeterminate />
                    </v-sheet>
                  </slot>
                  <slot v-else-if="noContentCondition && !loading" name="no-content">
                    <wsBlankPage
                        :text="noContentText"
                        :icon="noContentIcon"
                        :button-text="noContentButtonText"
                        :button-action="noContentButtonAction"
                        :route="noContentButtonRoute"
                        :loading="noContentLoading"
                    />
                  </slot>
                  <slot v-if="noContentCondition && !SM && !loading" name="no-content.desktop">
                  </slot>
                  <slot v-if="noContentCondition && SM && !loading" name="no-content.mobile">
                    <wsBlankPage
                        :text="noContentText"
                        :icon="noContentIcon"
                        :button-text="noContentButtonText"
                        :button-action="noContentButtonAction"
                        :route="noContentButtonRoute"
                        :loading="noContentLoading"
                    />
                  </slot>


                  <slot v-if="!noContentCondition && !loading" name="default"></slot>
                  <slot v-if="!SM && !noContentCondition && !loading" name="defaultDesktop"></slot>
                  <slot v-if="SM && !noContentCondition && !loading" name="defaultMobile"></slot>
                  <slot v-if="!SM && !noContentCondition && !loading" name="default.desktop"></slot>
                  <slot v-if="SM && !noContentCondition && !loading" name="default.mobile"></slot>






                </v-col>
              </v-row>


            </div>
          </div>
        </div>
      </v-col>

    </v-row>

    <!-- Dialogs Slot -->
    <div>
      <slot name="dialog"></slot>
    </div>

  </v-sheet>
</template>

<script>
import {mapState} from "vuex";
import wsSearchFilters from "@/components/dashboard/UI/wsSearchFilters";
import wsBlankPage from "@/components/UI/wsBlankPage";
export default {
  name: "DashPageNew",
  components : {
    wsSearchFilters,
    wsBlankPage
  },
  props : {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    filters : {
      type : Array,
      default() { return [] }
    },
    filtersInput : {
      type : Object,
      default: () => {}
    },
    root: {
      type: String
    },
    rootLocation: {
      type: String
    },
    icon: {
      type: String
    },
    width : {
      type: String,
      default : '100%'
    },
    headerRoute : {
      type: String,
      default : ''
    },
    sideNavigation : {
      type : Array,
      default: () => []
    },
    sideNavigationNoLink : {
      type : Boolean,
      default : false
    },
    navigationRoute  : {
      type : String,
      default : ''
    },
    value : {
      type : String,
    },
    mobileTitle : {
      type : String,
      default : ''
    },
    preheaderColor : {
      type : String,
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    navigationReturnRoute : {
      type : String,
    },
    noContentCondition : {
      type : Boolean,
      default : false
    },
    noContentText : {
      type : String
    },
    noContentIcon : {
      type : String
    },
    noContentButtonText : {
      type : String
    },
    noContentButtonRoute : {
      type : String
    },
    noContentButtonAction : {
      type : Function
    },
    noContentLoading : {
      type : Boolean,
      default : false
    },
    loading : {
      type : Boolean,
      default : false
    },
    noHeader : {
      type : Boolean,
      default : false
    }

  },
  data() {
    return {
      editName : false,
      filtersData : {},
      scroll : 0,
    }
  },
  watch : {
    sideNavigation : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    pageNavigationItems : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    filters : {
      handler() {
        this.initPage()
      },
      deep : true
    },
    filtersInput() {
      if ( this.filtersInput !== this.filtersData ) {
        this.filtersData = JSON.parse(JSON.stringify(this.filtersInput))
      }
    },
    scrollHeight(value) {
      this.$emit('scroll' , value)
    },
    $route (){
      if ( this.$refs.dashPage ) {
        let content = this.$refs.dashPage.$el
        if ( content ) {
          content.scrollTop = 0
          window.scrollTo(0, 0)
        }
      }
    }
  },
  computed : {
    ...mapState('dashboard',['pageNavigationItems']),
    scrollHeight() {
      if ( !this.$refs.dashPage ) {
        return 0
      }
      return this.$refs.dashPage.$el.scrollTop
    },
    element() {
      return this.$refs.dashPage
    },
    clearFiltersCondition() {
      let result = false

      Object.keys(this.filtersData).forEach(key=>{
        if ( this.filtersData[key] !== null && this.filtersData[key] !== undefined) {
          result = true
        }
      })

      return result
    }
  },
  methods : {
    setScrollPosition() {
      let element = this.$refs.dashPage
      if ( !element ) {
        return
      }
      let scroll = element.$el.scrollTop
      this.$emit('scroll' , scroll)
    },
    eraseFilter(key , index ) {
      if ( !index ) {
        this.filtersData[key] = null
        this.filtersData = JSON.parse(JSON.stringify(this.filtersData))
      } else {
        this.filtersData[key].splice(index,1)
        this.filtersData[key] = JSON.parse(JSON.stringify(this.filtersData[key]))
        this.filtersData = JSON.parse(JSON.stringify(this.filtersData))
      }
      this.$emit('filter',this.filtersData)
      this.filtersData = JSON.parse(JSON.stringify(this.filtersData))



    },
    initPage() {
      if ( this.filtersInput ) {
        this.filtersData = JSON.parse(JSON.stringify(this.filtersInput))
      }
      if ( this.sideNavigation.length > 0 ) {
        this.$store.state.dashboard.displayPageNavigation = true
        this.$store.state.dashboard.pageNavigationRoot = this.navigationRoute
        this.$store.state.dashboard.pageNavigationItems = this.sideNavigation
        this.$store.state.dashboard.pageNavigationReturnRoute = this.navigationReturnRoute

        let currentRoute = this.sideNavigation.find( el => this.$route.path.includes(this.navigationRoute + '/' + el.value) )
        if ( currentRoute ) {
          this.$store.state.dashboard.pageNavigation = currentRoute.value
        }

      }
    },
    getFilterText(key , value) {

      let filter = this.filters.find( el=>el.value === key )
      if ( !filter ) {
        return ''
      }
      let item = filter.items.find( el=> el.value === value)
      if ( !item ) {
        return ''
      }
      return item.text

    },
  },
  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.dashboard.pageNavigationReturnRoute = ''
    this.$store.state.dashboard.displayPageNavigation = false
    this.$store.state.dashboard.pageNavigationRoot = ''
    this.$store.state.dashboard.pageNavigationItems = []
  }
}
</script>

<style scoped>
</style>