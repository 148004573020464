const state = {
    notifications : [],

    notify       : '',
    notify_title : '',
    notify_text  : '',
    notify_image   : '',
}

const actions = {
}
export default {
    namespaced : true,
    state,
    actions
}