import wsDialog from "@/components/dashboard/UI/modal/wsDialog";
import wsTranslationField from "@/components/UI/header/wsTranslationField";
import wsEditMenu from "@/components/UI/wsEditMenu";
import wsTimePicker from "@/components/UI/wsTimePicker";
import wsDatePicker from "@/components/UI/wsDatePicker";
import wsPhoneInput from "@/components/UI/wsPhoneInput";
import wsColorPicker from "@/components/UI/wsColorPicker";
import wsCheckBox from "@/components/UI/wsCheckBox";
import wsChip from "@/components/UI/wsChip";
import ftSelect from "@/components/UI/ftSelect";
import wsTableHeader from "@/components/UI/tables/wsTableHeader";
import {mapState} from "vuex";
import wsIconButton from "@/components/UI/wsIconButton";
import wsPlainTextEdit from "@/components/UI/wsPlainTextEdit";
import wsTooltip from "@/components/UI/wsTooltip";
import wsFileUploader from "@/components/UI/wsFileUploader";
import wsEmbedEditor from "@/components/UI/embedVideo/wsEmbedEditor";
import wsEmbed from "@/components/UI/embedVideo/wsEmbed";
import dashPageNew from "@/components/dashboard/UI/dashPageNew";
import wsVideo from "@/components/UI/videoPlayer/wsVideo";
import wsVideoEditor from "@/components/UI/videoPlayer/wsVideoEditor";
import wsNavigationHeader from "@/components/UI/wsNavigationHeader";
import wsTextEditor from "@/components/UI/textEditor/wsTextEditor";
import wsTextField from "@/components/UI/wsTextField";
import wsAccordion from "@/components/UI/wsAccordion";
import wsTextViewer from "@/components/UI/textViewer/wsTextViewer";
import wsVideoViewer from "@/components/UI/textViewer/wsVideoViewer";
import wsAddItem from "@/components/UI/wsAddItem";
import wsSelect from "@/components/UI/wsSelect";
import wsToggle from "@/components/UI/wsToggle";
import wsDataTable from "@/components/UI/tables/wsDataTable";
import subPage from "@/components/dashboard/UI/subPage";
import wsSwitch from "@/components/UI/wsSwitch";
import wsPdf from "@/components/UI/pdfViewer/wsPdf";
import wsPdfDialog from "@/components/UI/pdfViewer/wsPdfDialog";
import wsImage from "@/components/UI/pdfViewer/wsImage";
import wsButtonGroup from "@/components/UI/wsButtonGroup";
import wsSlider from "@/components/UI/wsSlider";
import wsButton from "@/components/UI/wsButton";
import wsLink from "@/components/UI/wsLink";
import wsNavigation from "@/components/UI/wsNavigation.vue";

export default {
    components : {
        wsNavigation,
        wsLink,
        wsButton,
        wsSlider,
        wsButtonGroup,
        wsImage,
        wsPdfDialog,
        wsPdf,
        wsSwitch,
        subPage,
        wsDataTable,
        wsToggle,
        wsSelect,
        wsAddItem,
        wsVideoViewer,
        wsTextViewer,
        wsAccordion,
        wsTextField,
        wsTextEditor,
        wsNavigationHeader,
        wsVideoEditor,
        wsVideo,
        wsEmbed,
        wsEmbedEditor,
        wsFileUploader,
        wsIconButton,
        wsTableHeader,
        dashPageNew,
        wsDialog,
        wsTranslationField,
        wsEditMenu,
        wsTimePicker,
        wsDatePicker,
        wsPhoneInput,
        ftSelect,
        wsChip,
        wsColorPicker,
        wsCheckBox,
        wsPlainTextEdit,
        wsTooltip
    },
    data() {
        return {

            SUBTITLES_FORMATS : ['vtt','srt'],

            wsACCENT      : "#567186",
            wsDARK        : "#567186",
            wsDARKER      : "#283E4F",
            wsDARKLIGHT   : "#94b3c7",
            wsBACKGROUND  : "#DEE8EE",
            wsLIGHTACCENT : "#D7E5F0",
            wsLIGHTCARD   : "#F2F8FB",
            wsSUCCESS     : "#B3DF93",
            wsLIGHTSUCCESS: "#DDF5CD",
            wsATTENTION   : "#ED8A58",
            wsWARNING   : "#fe4b4b",
            wsDASHBACKGROUND  : "#DCE8EF",
            wsSUCCESSDARK : "#44655b",

            colors_fine : {
                wsACCENT      : "#5e55bf",
                wsDARK        : "#262443",
                wsDARKLIGHT   : "#4b5797",
                wsDARKER      : "#1d1d36",
                wsBACKGROUND  : "#edeeef",
                wsLIGHTACCENT : "#afafaf",
                wsLIGHTCARD   : "#f8f8f8",
                wsSUCCESS     : "#91c26c",
                wsSUCCESSDARK : "#44655b",
                wsLIGHTSUCCESS: "#DDF5CD",
                wsATTENTION   : "#e61743",
                wsWARNING   : "#fe4b4b",
            },

            colors_avalon : {
                wsACCENT      : "#393c65",
                wsDARK        : "#262443",
                wsDARKLIGHT   : "#4b5797",
                wsDARKER      : "#1d1d36",
                wsBACKGROUND  : "#edeeef",
                wsLIGHTACCENT : "#afafaf",
                wsLIGHTCARD   : "#f8f8f8",
                wsSUCCESS     : "#91c26c",
                wsSUCCESSDARK : "#44655b",
                wsLIGHTSUCCESS: "#DDF5CD",
                wsATTENTION   : "#e61743",
                wsWARNING   : "#fe4b4b",
            },

            colors_westudy : this.$store.state.colors,



        }
    },
    computed: {

        ...mapState('templates', [  'businessAlias', 'editorMode', 'menuItems' , 'templateVars_init', 'templateVars_current' , 'headerDark']),

        SHIFT_DOWN() {
            return this.$store.state.shiftDown
        },

        SM() {
            return this.displayShow(['sm'])
        },

        localeUrl() {
            return this.$i18n.locale === process.env.VUE_APP_I18N_LOCALE ? '' : `${this.$i18n.locale}/`
        },

        windowSize()  {
            let breakPoint = this.$vuetify.breakpoint.name
            if ( breakPoint === 'xs' ) { breakPoint = 'sm' }
            if ( breakPoint === 'xl' ) { breakPoint = 'lg' }
            return breakPoint
        },
        DEVICE() {
            let device = 'desktop'

            if ( this.$device.mobile ) {
                device = "mobile"
            }
            return device
        },
        PARAMS_languages() {
            if ( this.$store.state.avalon.viewAvalon ) {
                return this.$store.state.avalon.langs
            }
            return this.$store.state.languages
        },
        TINY_MCE() {
            return process.env.VUE_APP_TINY_MCE
        },
        TINY_MCE_LANG() {
            switch ( this.$i18n.locale ) {
                case 'ua': return 'uk'
                case 'cn': return 'zh_CN'
                default : return this.$i18n.locale
            }
        },
        LANG() {
            return this.$i18n.locale
        },
        LOADING() {
            return this.$store.state.LOADING
        },

        CHOICE_ACTIVE() {
            return [
                { text : this.$t('Active') , value: true },
                { text : this.$t('NotActive') , value: false }
            ]
        },
        CHOICE_ACTIVE_SELECT() {
            return [
                { text : this.$t('All')       , value: null },
                { text : this.$t('Active')    , value: true },
                { text : this.$t('NotActive') , value: false }
            ]
        },

        CHOICE_COMPLETED() {
            return [
                { text : this.$t('Completed')    , value: true },
                { text : this.$t('NotCompleted') , value: false }
            ]
        },
        CHOICE_COMPLETED_SELECT() {
            return [
                { text : this.$t('All')    , value: null },
                { text : this.$t('Completed')    , value: true },
                { text : this.$t('NotCompleted') , value: false }
            ]
        },
        CHOICE_PAYED() {
            return [
                { text : this.$t('PaymentConfirmed') , value: true },
                { text : this.$t('PaymentNotConfirmed') , value: false }
            ]
        },
        CHOICE_PAYED_SELECT() {
            return [
                { text : this.$t('All')    , value: null },
                { text : this.$t('PaymentConfirmed') , value: true },
                { text : this.$t('PaymentNotConfirmed') , value: false }
            ]
        },
        userLang() {
            return this.$store.state.auth.userLang
        },

    },
    watch : {
        userLang(value) {
            if ( value ) {
                this.LOCALE_CHANGE(value)
            }
        }
    },
    methods : {

        SHORTEN_FILE_NAME(fileName ) {
            let text = fileName
            if (fileName.length > 35 ) {
                 text = fileName.substring(0,17) + '...' + fileName.slice(-10)
            }
            return text
        },

        displayHide(sizes) {
            return !sizes.includes(this.windowSize )
        },
        displayShow(sizes) {
            return sizes.includes(this.windowSize )
        },
        localeLink(path , fullUrl = false , noLang = false){
            let domain = this.$store.state.appUrl
            let link = ''
            if ( !noLang ) {
                link = `/${this.localeUrl}${path}`
            } else {
                link = `/${path}`
            }

            if ( fullUrl ) {
                link = domain + link
            }
            return link
        },
        dashboardLink(route , admin = true , fullUrl = false) {
           if ( !admin ) {
               return ''
           }
            var domain = this.$store.state.appUrl
            
            if ( !route ) {
                let result =  `/${this.localeUrl}dashboard/`
                if ( fullUrl ) {
                    result = domain + result
                }
                return result
            }
            let result =  `/${this.localeUrl}dashboard/` + route
            if ( fullUrl ) {
                result = domain + result
            }
            return result
            
              

        },

        // data valiadation

        NUMBER_INPUT(input,data,length, dec = false) {
            if ( !data ) {
                data = ''
            }
            input = (input) ? input : window.event;
            var charCode = (input.which) ? input.which : input.keyCode;
            if (
                ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 ) ||
                ( data.length >= length && charCode !== 8  )
            ) {
                if ( dec && charCode === 190) { return true }
                input.preventDefault();
            } else {
                return true;
            }


        },

        LOCALE_CHANGE(lang) {
            if ( localStorage.getItem('locale') !== lang ) {
                 localStorage.setItem('locale',lang)
            }
            this.$i18n.locale = lang
        },

        //technical
        init_colors( colorSet ) {

            Object.keys(colorSet).forEach((key) => {
                this[key] = colorSet[key]
            })

        },
        PARSE_FILE_SIZE(size = 0) {
            size = parseInt(size) || 0

            let result = ( size/1000/1000).toFixed(2)
            result += ' MB'
            return result
        },
        capitalizeFirstChar(string) {
            return string[0].toUpperCase() + string.slice(1)
        },
        EQ( a, b ) {

            a = !a ? null : JSON.stringify(a)
            b = !b ? null : JSON.stringify(b)

            return a === b
        },
        COPY(object) {
            if ( !object ) {
                return null
            }
            return JSON.parse(JSON.stringify(object))
        }

    },
    mounted() {
        // init color scheme
        document.body.style.backgroundColor = '#ffffff';
    }
}