<template>
  <dash-page-new
      title="Current states"
      subtitle="States STATESS"
      root="Dashboard"
      icon="mdi-server-network"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #header_action>
      <div class="d-flex align-center mr-3">
        <ws-toggle
            v-model="displayCard"
            text-true="Card"
            text-false="Table"
        />
      </div>
    </template>

    <template #default>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Online servers</h3>
      </v-sheet>

      <v-sheet
          v-for="(message, i) in infoMessages" :key="'info' + i"
          :style="`border: 1px solid ${wsBACKGROUND}`"
          :color="wsATTENTION + '33'"
          class="pa-4 pr-8 mt-6 wsRoundedHalf mb-6"
      >
        <div class="d-flex align-center">
          <v-icon class="mr-5" :color="wsATTENTION">mdi-alert</v-icon>
          <div class="d-flex justify-space-between align-center" style="width: 100%">
            <span class="font-weight-regular">{{ message }}</span>
          </div>
        </div>
      </v-sheet>

      <v-sheet
          v-for="(message, i) in fatalMessages" :key="'fatal' + i"
          :style="`border : 1px solid ${wsBACKGROUND}`"
          :color="wsWARNING + '33'"
          class="pa-4 pr-8 mt-6 wsRoundedHalf mb-6"
      >
        <div class="d-flex align-center">
          <v-icon class="mr-5" :color="wsWARNING">mdi-alert</v-icon>
          <div class="d-flex justify-space-between align-center" style="width: 100%">
            <span class="font-weight-regular">{{ message }}</span>
          </div>
        </div>
      </v-sheet>

      <!-- Table view -->
      <div v-if="!displayCard">
        <ws-data-table
            :row-action="openServerDetails"
            :items="tableServers"
            :headers="headers"
            class="mt-6"
        >
          <template #item.name="{item}">
            <router-link :to="dashboardLink(`states/${item.name}`)" class="noUnderline">
              <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
            </router-link>
          </template>
        </ws-data-table>
      </div>

      <!-- Card view -->
      <div v-else>
        <v-row class="mt-5">
          <v-col
              v-for="(server,name) in servers"
              :key="name"
              cols="12"
              md="4"
              xl="3"
          >
            <v-hover #default="{hover}">
              <router-link :to="dashboardLink(`states/${name}`)" class="noUnderline">
                <v-sheet
                    @click="$router.push(dashboardLink(`servers/${name}`))"
                    :color="!hover ? wsLIGHTCARD : wsACCENT"
                    :dark="hover"
                    :class="[{'pointer': hover}]"
                    class="pa-3 wsRoundedHalf fill-height"
                >
                  <h4 color="wsACCENT">
                    <v-icon class="mr-2" color="wsACCENT">mdi-server</v-icon>
                    {{ name }}
                  </h4>
                  <v-divider class="my-3"/>
                  <h5 v-if="server.is_dev === true">
                    IS DEV
                  </h5>
                  <h5>
                    Type: {{ server.type }}
                  </h5>
                  <h5 :style="`color: ${ server.cpu.load.value > server.cpu.load.critical ? wsWARNING : 'black' }`">
                    CPU: {{ server.cpu.load.value }} {{ server.cpu.load.unit }}
                    <b v-if="server.cpu.load.value >= server.cpu.load.critical">( ! )</b>
                  </h5>
                  <h5 :style="`color: ${ server.hdd.load.value > server.hdd.load.critical ? wsWARNING : 'black' }`">
                    HDD: {{ server.hdd.load.value }} {{ server.hdd.load.unit }}
                    <b v-if="server.hdd.load.value >= server.hdd.load.critical">( ! )</b>
                  </h5>
                  <h5 :style="`color: ${ server.ram.load.value > server.ram.load.critical ? wsWARNING : 'black' }`">
                    RAM: {{ server.ram.load.value }} {{ server.ram.load.unit }}
                    <b v-if="server.ram.load.value >= server.ram.load.critical">( ! )</b>
                  </h5>
                  <h5 v-if="'cloud' in server && 'converter' in server.cloud">
                    Converters online: {{ server.cloud.converter.count.online }}
                  </h5>
                  <h5 v-if="'cloud' in server && 'converter' in server.cloud">
                    Converters busy: {{ server.cloud.converter.count.working }}
                  </h5>
                </v-sheet>
              </router-link>
            </v-hover>
          </v-col>
        </v-row>
      </div>
    </template>
  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "StatesTable",
  data() {
    return {
      timer: null,
      nameError: null,
      typeError: null,
      entityData: {},
      servers: {},
      tableServers: [],
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Type', value: 'type'},
      ],
      serverTypes: [
        {text: 'api', value: 'api'},
        {text: 'cloud', value: 'cloud'},
        {text: 'health', value: 'health'},
      ],
      infoMessages: [],
      fatalMessages: [],
      displayCard: true,
    }
  },
  methods: {
    ...mapActions("servers", [
      "GET_SERVERS_HEALTH",
    ]),
    async syncHealthData() {
      this.infoMessages = [];
      this.fatalMessages = [];

      let data = await this.GET_SERVERS_HEALTH();
      if (!data) {
        return this.notify(this.$store.state.ajax.error, "error");
      }

      this.tableServers = [];
      Object.keys(data).forEach(server => {
        this.tableServers.push({
          name: server,
          type: data[server].type,
        });

        if (data[server]['info'].length > 0) {
          Object.keys(data[server]['info']).forEach(info => {
            this.infoMessages.push(data[server]['info'][info])
          });
        }

        if (data[server]['fatals'].length > 0) {
          Object.keys(data[server]['fatals']).forEach(info => {
            this.fatalMessages.push(data[server]['fatals'][info])
          });
        }
      })

      this.servers = this.COPY(data);
    },
    initData() {
      this.syncHealthData();
    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>