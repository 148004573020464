const state = {
    connectedCallbacks : [],
    connectInterval : 10000
}
const actions = {
    INIT_SOCKET({rootState}, {socket,sockets}) {
        rootState.$socket = socket
        rootState.$sockets = sockets
    },
    EMIT({rootState} , body ) {
        console.log('EMIT' , body)
        return new Promise((resolve) => {
            const onResponse = (response) => {
                rootState.$sockets.unsubscribe(body.action); // Remove the listener
                if ( !response ) {
                    resolve(false)
                    console.log('[socket_response] : No response' )
                    return
                }
                if ( !response.result ) {
                    console.log('[socket_response] : No result' )
                    resolve(false)
                    return
                }
                if ( body.callback ) {
                    console.log('[socket_response] : Have callback' )
                    body.callback(response.data || null)
                    resolve(true)
                    return
                }
                if ( !response.data ) {
                    console.log('[socket_response] : No data' )
                    resolve(true)
                    return
                }
                console.log('[socket_response] : Resolving data' , response.data )
                resolve(response.data);
            }

            rootState.$sockets.subscribe(body.action, onResponse );
            rootState.$socket.emit(body.action, body.data);

            setTimeout(() => {
                rootState.$sockets.unsubscribe(body.action); // Remove the listener
                resolve(false)
            }, 60000);

        });
    },
}
export default {
    namespaced : true,
    actions,
    state
}