export default {
    computed : {
        IS_BUSINESS_OWNER() {
            return this.$store.state.auth.userBusinesses
                .includes(this.$store.state.business.selectedBusiness.alias)
        },
        IS_BUSINESS_MANAGER() {
            return this.$store.state.auth.userManagingBusinesses
                .includes(this.$store.state.business.selectedBusiness.alias)
        },
        IS_TESTER() {
            return this.$store.state.auth.user.roles.includes('TESTER')
        }
    },
    methods : {
        ROLE(...roles) {

            var access = false
            roles.forEach( (role)=> {
                if (role === 'All') { access = true }
                if (this.$store.state.auth.userType === role) { access = true }
            })
            return access;

        },
        IS_MODULE_ACTIVE(moduleName) {
            let module = this.$store.state.business.selectedBusiness.modules.find(el=>el.name === moduleName )
            if (!module) { return false }
            return !!module.active
        },
        MODULE_PERMISSIONS(modules,  fullName = false) {

            let permission = true
            if ( !this.$store.state.auth.loggedIn) { return false }
            modules.forEach( (module) => {
                permission = false
                if ( this.IS_BUSINESS_OWNER || this.IS_BUSINESS_MANAGER  ) {
                    permission = true }
                let moduleName = fullName ? module.replace('MODULE_','').toLowerCase() : module;
                if ( !this.IS_MODULE_ACTIVE(moduleName.split('__')[0]) ) {
                    permission = false         }

                if ( this.IS_BUSINESS_MANAGER ) {
                    if ( !this.$store.state.auth.businessManagerPermissions[this.$store.state.business.selectedBusiness.alias] ) {
                        permission = false
                    }
                    let fullPermissionName = "MODULE_" + module.toUpperCase()
                    let modulePermission = fullName ? module : fullPermissionName;
                    if (!this.$store.state.auth.businessManagerPermissions[this.$store.state.business.selectedBusiness.alias].includes(modulePermission) ) {
                        permission = false
                    }
                }
            })

            return permission
        },
        async CHECK_INVITATION_CODE() {

            if ( !this.$route.query  ) {
                return
            }
            if ( !this.$route.query.auth  ) {
                return
            }
            let code = this.$route.query.auth
            if ( code ) {
                let result = await this.$store.dispatch('auth/LOGIN_FROM_CODE' , code )
                if ( !result ) {
                    return true
                }
            }

        },
    }
}