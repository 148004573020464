<template>
  <ws-text-field
      v-model="phone"
      ref="phone"
      :placeholder="placeholder"
      :label="label"
      :error-messages="errorMessage || errorMessages"
      :error="error"
      :hide-details="hideDetails"
      :dense="dense"
      :background-color="backgroundColor"
      :dark="dark"
      :default-style="defaultStyle"
      outlined
      number
      hide-number-handle
      :width="width"
      :label-color="labelColor"
      clearable
      :required="required"
  >

    <template  slot="prepend-inner" class="fill-height" >

      <ft-select
          v-model="countryCode"
          :items="countryCodes"
          :closeOnClick="false"
          display-value
          :disabled="readonly"
          searchable>

        <div class="d-flex align-center">
          <h5 class="grey--text text--lighten-1 font-weight-medium" style="padding-top: 5px" >
            {{ countryCode }}
          </h5>
          <v-icon class="mt-1" small>mdi-chevron-down</v-icon>
        </div>

      </ft-select>

    </template>

  </ws-text-field>
</template>

<script>
import countryCodes from '../../assets/json/country_codes_select.json'

export default {
  name: "wsPhoneInput",
  props : {
    value : {
      type : String,
      default : ''
    },
    dense : {
      type : Boolean,
      default : true
    },
    label : {
      type : String,
      default : ''
    },
    placeholder : {
      type : String,
      default : '063-111-22-33'
    },
    hideDetails : {
      type : Boolean,
      default : false
    },
    labelColor : {
      type : String,
    },
    backgroundColor : {
      type : String,
      default : 'transparent'
    },
    readonly : {
      type : Boolean,
      default : false
    },
    dark : {
      type : Boolean,
      default : false
    },
    defaultStyle : {
      type : Boolean,
      default : false
    },
    error : {
      type : Boolean,
      default : false
    },
    width : {
      default : null
    },
    errorMessages : {
      type : Array,
      default : () => []
    },
    required : {
      type : Boolean,
      default : false
    },


  },
  data() {
    return {
      phone : '',
      countryCode : '+380',
      errorMessage : ''
    }
  },
  computed : {
    phoneNumber() {
      return this.countryCode + this.phone
    },
    countryCodes() {
      return countryCodes
    }
  },
  watch : {
    phone(value) {
      this.checkPhone(value)
    },
    countryCode() {
      this.checkPhone()
    },
    value(value) {
      let number = this.countryCode + this.phone

      if ( number!== value) {
        this.checkCode(value  )
      }
    }
  },
  methods : {
    onlyNumberInput(input){

      if ( this.phone.length > 10 ) {
        input.preventDefault();
      }

      input = (input) ? input : window.event;
      var charCode = (input.which) ? input.which : input.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        input.preventDefault();
      } else {
        return true;
      }

    },
    checkCode(number) {
      if ( this.countryCodes.length === 0 ) {
        return
      }
      let codesArray = this.countryCodes.map( el => el.value)
      codesArray.forEach((code) => {
        if (number.includes(code)) {
          this.countryCode = code
          this.phone = number.replace(code,'')
        }
      })
    },
    checkPhone(value) {

      if ( value && value.length < 8 ) {
        this.$emit('input','')
        return this.errorMessage = 'Номер телефона складається з 10 цифр'
      } else {
        this.errorMessage = ''
        if (this.phoneNumber.length === 3 ) {
          this.$emit('input','')
        } else {
          this.$emit('input',this.phoneNumber)
        }
      }
    },
    getNumber(number) {
      if ( this.countryCodes.length === 0 ) {
        return
      }
      let codesArray = this.countryCodes.map( el => el.value)
      codesArray.forEach((code) => {
        if (number.includes(code)) {
          return number.replace(code,'')
        }
      })

      return null
    }
  },
  mounted() {

    if  (this.value ) {
      if ( this.value.length > 10 ) {
        this.checkCode(this.value)
      }
    }
  }

}
</script>

<style scoped>

</style>