<template>
  <div>
     <v-data-table
         @current-items="$emit('current-items' , $event)"
         :items="items"
         :headers="headers"
         :search="search"
         :items-per-page="4"
         :footer-props="{ itemsPerPageOptions : [25,50,100,-1]}"
         hide-default-header
         hide-default-footer
         :disable-pagination="disablePagination"
         class="pb-4 wsHoverLight"
         :dense="dense"

     >
    <template v-slot:header="{ props , on }">
      <wsTableHeader v-if="!noHeader" :props="props" :on="on" />
    </template>

    <template #body="{items}">
      <tbody>
      <template v-for="(item,index) in items" >
        <slot  @contextmenu="openContextMenu($event, item)"  name="item" :item="item" :index="index"
               :isSelected="isSelectedRow(item.uuid)"
               :context-action="openContextMenu">
          <v-hover v-slot="{hover}">

            <tr :style="getRowColor(hover, item.uuid)"
                :class="[{ 'pointer' : rowAction }]"
                @click="action(item)"
                @contextmenu="openContextMenu"
                :key="index">
              <td v-for="column in headersFiltered"
                  :style="`border-color: ${wsBACKGROUND}`"
                  :key="'column' + index + column.value"
                  :width="column.width"
              >
                <slot :name="'item.' + column.value " :item="item" :index="index" :isSelected="isSelectedRow(item.uuid)"  >
                  {{ item[column.value] }}
                </slot>

              </td>
            </tr>

          </v-hover>
        </slot>

      </template>

      </tbody>
      <tfoot>
      <tr>
        <td height="1px" style="height: 1px !important;" :colspan="headers.length" class="pa-0 ma-0">
          <v-divider class="mb-3" :style="`border-color: ${wsBACKGROUND}`" />
        </td>
      </tr>

      </tfoot>

    </template>

    <template #footer="{props}">
      <slot v-if="!disablePagination" name="footer">
        <v-sheet v-if="displayPaginationCondition(props)"
                 class="py-3 justify-end d-flex align-center"
                 color="transparent" >
          <h5 class="mr-6">{{ $t('RowsPerPage') }} </h5>
          <ws-select class="mr-6"
                     :items="paginationSelect(props)"
                     v-model="props.options.itemsPerPage"
                     width="100px"></ws-select>

          <v-btn @click="props.options.page--"
                 :disabled="props.options.page <2"
                 icon :color="wsACCENT"><v-icon large>mdi-chevron-left</v-icon></v-btn>
          <h5 class="mx-4">{{ $t('RowsDisplayed') }} {{ props.pagination.pageStart + 1 }} - {{ props.pagination.pageStop }} із
            {{ items.length }}</h5>
          <v-btn @click="props.options.page++"
                 :disabled="props.options.page >= props.pagination.pageCount"
                 icon :color="wsACCENT"><v-icon large>mdi-chevron-right</v-icon></v-btn>
        </v-sheet>

        <div v-else>
          <h5  class="text-right pr-8">
            {{ $t('RowsDisplayed') }} : {{ items.length }}
          </h5>
        </div>
      </slot>

    </template>


  </v-data-table>

     <ft-select
         v-if="contextActionsSelect.length > 0"
         @input="$emit('contextAction' , $event,  selectedItem); $emit('itemSelect' , selectedItem)"
         :items="contextActionsSelect"
         :expanded="displayContextMenu"
         @expand="displayContextMenu = $event"
         :x="x"
         :y="y"
         absolute
     />
  </div>

</template>

<script>
export default {
  name: "wsDataTable",
  props : {
    headers : {
      type : Array,
      default : () => []
    },
    items : {
      type : Array,
      default : () => []
    },
    contextActionsSelect : {
      type : Array,
      default : () => []
    },
    search : {
      type : String,
      default : ''
    },
    dense : {
      type : Boolean,
      default : false
    },
    noHeader : {
      type : Boolean,
      default : false
    },
    rowAction : {
      type : Function,
    },
    disablePagination : {
      type : Boolean,
      default : false
    },
    selectedRow : {
      type : String
    }
  },
  data() {
    return {
      displayContextMenu : false,
      x : 0,
      y : 0,
      selectedItem : {},
    }
  },
  computed : {
    headersFiltered() {

      let headers = this.headers
      if ( headers.length === 0) {
        return []
      }
      headers = headers.filter(el => el.align !== 'd-none')

      return headers

    }
  },
  methods : {
    getRowColor(hover , uuid) {
      if ( this.isSelectedRow(uuid) ) {
        return `background : ${this.wsDARK} !important`
      }
      return `background : ${ hover ? this.wsLIGHTCARD : ''} !important`
    },
    isSelectedRow( uuid) {
      return !!this.selectedRow && this.selectedRow === uuid
    },
    openContextMenu($event , item) {
      if ( this.contextActionsSelect.length === 0 ) {
        return
      }
      this.selectedItem = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })

      if ( this.contextActionsSelect.length > 0  ) {
        this.displayContextMenu = true
      }
    },
    action(item) {
      if ( this.rowAction ) {
        this.rowAction(item)
      }
    },

    paginationSelect(props) {
      let items = []
      if ( props.itemsPerPageOptions) {
        props.itemsPerPageOptions.forEach(item=>{
          items.push( { text : (item !== -1 ? item : this.$t('All'))  , value : item  })
        })
      }
      return items
    },
    displayPaginationCondition(props) {
      return props.pagination.pageCount > 1 || this.itemsPerPageIndex(props) !== 0
    },
    itemsPerPageIndex(props) {
      return props.itemsPerPageOptions.findIndex( el => el === props.options.itemsPerPage)
    },

  }
}
</script>

<style scoped>

</style>