const actions = {

    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    // BUSINESS_ADMIN
    async GET_BUSINESS_USERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',{
            route:`business/users`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
        }, {root : true} );
    },
    // USER
    async GET_PROFILE({dispatch}) {
        return await dispatch('ajax/SEND_NEW',{
            route:`user/profile`,
            method:'GET'
        }, {root : true} );
    },
    async EDIT_PROFILE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`user`,
                method:'PUT',
                data : body
            }, {root : true} );
    },
    async UNLINK_TELEGRAM({rootState,dispatch}) {
        return await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken, route:`user/unlink/telegram`, method:'GET'} );
    },



}

export default {
    namespaced:true,
    actions
}