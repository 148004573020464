var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('h5',{staticClass:"mb-2",staticStyle:{"color":"#4F5A6E","font-size":"13px","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.customActivator)?_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 ",class:{
            activeText : _vm.focused && !_vm.error && !_vm.defaultStyle,
            notActive : !_vm.focused && !_vm.error && !_vm.defaultStyle,
            errorStyle : _vm.error && !_vm.defaultStyle,
            textStyle : !_vm.defaultStyle
          },attrs:{"dense":_vm.dense,"color":!_vm.defaultStyle ? _vm.wsACCENT : '',"placeholder":_vm.placeholder,"prepend-inner-icon":"mdi-calendar","hide-details":"","readonly":"","outlined":"","clearable":_vm.clearable},on:{"focusin":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false ; _vm.$emit('change',_vm.text)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on)):_vm._e(),(_vm.customActivator)?_c('v-sheet',_vm._g(_vm._b({attrs:{"color":"transparent"}},'v-sheet',attrs,false),on),[_vm._t("default")],2):_vm._e()]}}],null,true)},[_c('v-date-picker',{ref:"picker",attrs:{"light":"","min":"1900-01-01","no-title":"","locale":"uk-UA"},on:{"change":function($event){return _vm.$refs.menu.save()}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }