<template>
  <div>

    <!-- Heading-->
    <div class="d-flex justify-space-between align-center mb-8">
      <h2 :style="`font-size: ${!SM ? 24 : 18}px`">
        {{ $t('Login') }}
      </h2>
    </div>

    <!-- Login Window -->
    <div>
      <ws-text-field
          v-if="fromEmail"
          v-model="entityData.email"
          :label="$t('Email')"
          :placeholder="$t('EnterEmail')"
          @input="emailError = null"
          :error="!!emailError"
          :error-messages="emailError"
          :hide-details="false"

      />
      <ws-text-field
          v-model="entityData.password"
          :label="$t('Password')"
          :placeholder="$t('Password')"
          @input="passwordError = null"
          :error="!!passwordError"
          :error-messages="passwordError"
          :hide-details="false"
          password
      />
    </div>
    <!-- Buttons -->
    <ws-button
        @click="login"
        label="Login"
        class="mt-6"
        block
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "loginWindow",
  props : {
    hideRegister : {
      type : Boolean,
      default : false
    },
    onAuth : {
      type : Function
    },
    authRoute : {
      type : String
    },
    noAuthAction : {
      type : Boolean,
      default : false
    },
    dialog : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      fromEmail : true,
      entityData : {
        email : null
      },
      emailError : null,
      phoneError : null,
      passwordError : null,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
    }
  },
  computed : {
    ...mapState('auth' , ['socialsAuthUrlGoogle' , 'socialsAuthUrlFacebook'])
  },
  methods : {

    ...mapActions('auth',[
        'LOGIN',
    ]),

    async login() {
      if (!this.validateInput()) {
        return
      }

      let result = await this.LOGIN(this.entityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'Incorrect password') {
          this.passwordError = this.$t('WrongPassword');
        }
        if ( this.$store.state.ajax.error === 'User not found') {
          if ( this.fromEmail ) {
            this.emailError = this.$t('UserNotFound');
          } else {
            this.phoneError = this.$t('UserNotFound');
          }

        }
        return
      }

      if ( this.noAuthAction) {
        this.$emit('success')
        return
      }

      this.$router.push(this.localeLink('dashboard'))
    },
    validateInput() {

      if ( this.fromEmail ) {
        this.entityData.email = this.entityData.email.replace(/ /g,'');
      }

      if ( this.fromEmail && !this.entityData.email ) {
        this.emailError = this.$t('WrongEmail')
        return false
      }

      if( this.fromEmail && !this.reg.test(this.entityData.email) ) {
        this.emailError = this.$t('WrongEmail')
        return false
      }
      if ( !this.fromEmail && !this.entityData.phone ) {
        this.phoneError = this.$t('EnterPhone')
        return false
      }
      if ( !this.entityData.password ) {
        this.passwordError = this.$t('EnterPassword')
        return false
      }
      return true
    },
  }
}
</script>

<style scoped>

</style>