<template>
  <dash-page-new
      title="GIT"
      subtitle="Projects Version Control"
      root="Dashboard"
      icon="mdi-git"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #default>

      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>GIT</h3>
        <h4 class="mt-3">List of GIT projects with controll functions to merge projects or update (pull) among all available servers </h4>

      </v-sheet>


    </template>

  </dash-page-new>
</template>

<script>
export default {
  name: "DashGit"
}
</script>

<style scoped>

</style>