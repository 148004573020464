<template>
  <v-sheet color="white" class="pt-6"
           :class="[{'pb-6' : !dialog}]"
           :width="contentWidth">

    <v-fade-transition mode="out-in">
      <login-window
          @restore="openRestorePassword"
          :auth-route="authRoute"
          :hide-register="hideRegister"
          :no-auth-action="noAuthAction"
          @success="$emit('success')"
          key="login"
          :dialog="dialog"
      />
    </v-fade-transition>
  </v-sheet>
</template>

<script>
import loginWindow from "@/components/auth/UI/loginWindow";

export default {
  name: "authWindowNew",
  components : {
    loginWindow,
  },
  props : {
    width : {
      type : String
    },
    displayRegister: {
      type : Boolean,
      default : true
    },
    displayLogin: {
      type : Boolean,
      default : false
    },
    displayRestorePassword: {
      type : Boolean,
      default : false
    },
    hideLogin: {
      type : Boolean,
      default : false
    },
    hideRegister: {
      type : Boolean,
      default : false
    },
    authRoute : {
      type : String
    },
    noAuthAction : {
      type : Boolean,
      default : false
    },
    isNewBusiness : {
      type : Boolean,
      default : false
    },
    dialog : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      entityData : {},
      display : 'register'
    }
  },
  computed : {
    contentWidth() {
      if ( this.dialog ) {
        return '100%'
      }
      return this.width || (this.SM ? 300 : 350)
    },
    socialsReturnPath() {
      if ( this.authRoute ) {
        return  this.authRoute.substring(1)
      }
      return this.$route.path.substring(1)
    }
  },
  methods : {
    openLogin() {
      this.display = 'login'
    },
    openRegister() {
      this.display = 'register'
    },
    openRestorePassword() {
      this.display = 'restore_password'
    }
  },
  beforeMount() {
    if (this.displayRegister || this.hideLogin) {
      this.dipslay = 'register'
    }
    if (this.displayLogin || this.hideRegister) {
      this.openLogin()
    }
    if (this.displayRestorePassword ) {
      this.display = 'restore_password'
    }
  }
}
</script>

<style scoped>

</style>