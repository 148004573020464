<template>
  <v-sheet color="transparent">

    <v-simple-table style="background-color: transparent; border-radius: unset !important"  >
      <tbody style="border-radius: unset !important">
      <tr style="background-color: transparent" >

        <td v-if="padding"
            :style="`width: ${padding}; border-bottom : 4px solid ${wsBACKGROUND };`"
            class="px-0"
        />
        <template v-for="(item,i) in items">

          <td  :key="i"
               @click="navigationAction(item)"
               width="10px" class="text-no-wrap pl-0"
               style="border-radius: unset !important"
               :style="getColummStyle(item,i)"
          >

            <h5 style="font-size: 14px; font-weight: 600"
                :style="
              `color: ${ item.disabled ? 'grey' : navigation === item.value ?  wsATTENTION : wsACCENT};
               font-weight : ${ navigation === item.value ?  '600' : '400' } !important`">

              <span v-if="!noNumber">{{ i+1 }}. </span>
              <span>{{ item.text }}</span>


            </h5>
          </td>
          <td :key="i + 'padding'" v-if="padding"
              :style="`width: ${padding}; border-bottom : 4px solid ${wsBACKGROUND };`"
              class="px-0"
          />
        </template>


        <td  style="border-radius: unset !important"
            :style="`border-bottom : 4px solid ${wsBACKGROUND}`"></td>
        <td v-if="padding"
            :style="`width: ${padding}; border-bottom : 4px solid ${wsBACKGROUND };`"
            class="px-0"
        />
      </tr>
      </tbody>

    </v-simple-table>

    <slot name="default"></slot>

    <template v-for="item in items" >
      <div v-if="navigation === item.value" :key="item.value">
        <slot :name="'item.' + item.value"></slot>
      </div>
    </template>

  </v-sheet>
</template>

<script>
export default {
  name: "wsNavigationHeader",
  props : {
    value : {},
    items : {
      type : Array,
      default() { return [] }
    },
    locked : {
      type : Boolean,
      default : false
    },
    noNumber :{
      type : Boolean,
      default : false
    },
    padding : {
      type : String
    }
  },
  data() {
    return {
      navigation : ''
    }
  },
  watch: {
    value() {
      if (this.value !== this.navigation ) {
        this.navigation = this.value
      }
    }
  },
  methods : {
    navigationAction(item) {
      if ( item.disabled ) {
        return
      }
      if ( !this.locked) {
        this.$emit('input',item.value)
      }
    },
    getColummStyle(item) {

      let style =  `border-bottom : 4px solid ${ this.navigation === item.value ?  this.wsATTENTION : this.wsBACKGROUND };`;

      if ( !this.locked && !item.disabled  ) {
        style += 'cursor : pointer'
      }
      if (item.disabled ) {
        style += 'cursor : not-allowed'
      }


      return style

    }
  },
  mounted() {
    if (this.value) {
      this.navigation = this.value
    }
  },
}
</script>

<style scoped>

</style>