<template>
  <v-sheet   >

    <div  class="fill-height">
      <iframe  frameBorder="0"  class="pdfStyle" width="100%" :height="`${height}px`" :src="url"></iframe>
    </div>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";


export default {
  name: "wsVideo",
  props : {
    file : {
      type : Object,
      default() { return {} }
    },
    src : {
      type : String,
      default :''
    },
    courseId : {
      type : String
    },
    student : {
      type : Boolean
    },
    testId : {
      type : String,
    },
    fullScreen : {
      type : Boolean,
      default : false
    },
    isPublic : {
      type : Boolean,
      default : false,
    },
    height : {
      type : Number,
      default : 350
    },
    isCollapsed : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      uuid : '',
      url : null,
    }
  },
  watch: {
    file() {
      this.initFile()
    }
  },
  methods : {
    ...mapActions('upload', [ 'GET_PRIVATE_FILE', 'GET_PUBLIC_FILE', 'GET_STUDENT_COMPONENT_FILE', 'GET_STUDENT_TEST_FILE' ]),

    async getFile( ) {

      if ( this.student ) {
        return this.openStudentFile(this.file)
      }
      //
      // if ( !this.file.mime.includes('video')) {
      //   this.notify(this.$t('WrongVideoFileFormat'))
      // }
      let result = this.isPublic ? await this.GET_PUBLIC_FILE(this.file.uuid) :  await this.GET_PRIVATE_FILE(this.file.uuid)
      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url
      
    },

    async openStudentFile(file) {



      let data =  null
      let result = null;
      if ( this.testId ) {
        data = {
          file : file.uuid,
          test : this.testId
        }
        result = await this.GET_STUDENT_TEST_FILE(data)
      } else {
        data = {
          file : file.uuid,
          course : this.courseId
        }
        result = await this.GET_STUDENT_COMPONENT_FILE(data)
      }

      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url
      
    },

    initFile() {
      if (this.file.uuid) {
        this.getFile()
      }
    }

  },
  mounted() {
    this.initFile()
  }
}
</script>

<style scoped>
.pdfStyle {
  border: none;
  padding: 0 !important;
  background-color: white;
}
</style>