import getRouter  from '@/router/router.js'
import getStore   from '@/store/store.js'
import makeAjaxNew   from './plugins/ajax_new';
import makeSocket from "@/plugins/socket";
import makeI18n   from "@/plugins/i18n"

const i18n     = makeI18n()
const ajax_new = makeAjaxNew( process.env.VUE_APP_API_URL , i18n );
const store    = getStore(ajax_new, process.env.VUE_APP_API_URL, process.env.VUE_APP_MAIN_HOST, process.env.VUE_APP_SECONDARY_HOST)
const router   = getRouter();
const socket   = makeSocket(store, process.env.VUE_APP_WS_SOCKET);

export default {
    i18n,
    ajax_new,
    router ,
    store  ,
    socket
}