<template>
  <v-sheet
      :style="`border: 1px solid ${wsACCENT}; color: ${wsACCENT}`"
      :color="wsLIGHTCARD"
      class="wsRoundedLight pa-4 my-4" >

    <div class="d-flex justify-space-between">
      <h5 >
        <v-icon class="mr-2" :color="wsACCENT">mdi-video</v-icon>
        {{ $t('VideoUploader') }}
      </h5>
      <div>
        <v-btn
            v-if="displayCancelButton"
            height="40"
            @click="cancel"
            outlined
            :color="wsACCENT"
            class="noCaps mr-2"> {{ $t('Close') }}
        </v-btn>
<!--        <v-btn-->
<!--            height="40"-->
<!--            @click="save"-->
<!--            :disabled="!video.uuid"-->
<!--            :outlined="!video.uuid"-->
<!--            dark-->
<!--            :color="wsATTENTION"-->
<!--            class="noCaps"> {{ $t('Save') }}-->
<!--        </v-btn>-->
      </div>

    </div>

    <wsNavigationHeader
        v-model="navigation"
        :items="navigationItems"
        :locked="!video.uuid"
    />

    <div v-if="navigation === 'video'" >
      <div class="d-flex justify-end">
        <ws-storage-file-picker
            v-model="video"
            @input="uploadFile"
            video
            class="mt-3"
        />
      </div>

      <ws-file-uploader
          v-if="displayVideoUpload"
          @success="uploadFile"
          @loading="videoLoading = $event"
          class="mt-4"
          video
          :isPublic="isPublic"
          :superadmin="superadmin"
          :video-qualities="selectedFormats"
          convert-video
      />

      <div v-else>
        <div class="d-flex align-center justify-space-between">
          <h5 class="my-4 d-flex align-center">
            <v-icon class="mr-1" small :color="wsACCENT">mdi-file</v-icon>
            {{ video.name }}
          </h5>
          <v-btn @click="displayVideoUpload = true" class="noCaps" :color="wsACCENT" text > {{ $t('Change') }}</v-btn>
        </div>
        <wsVideo
            :file="video" />
      </div>

    </div>
    <div v-if="navigation === 'image'" >

      <ws-file-uploader
          v-if="displayImageUpload"
          @success="uploadFile"
          class="mt-4"
          public
          :formats="['jpg','jpeg']"
          image
      />
      <div v-else>
        <div class="d-flex align-center justify-space-between">
          <h5 class="my-4 d-flex align-center">
            <v-icon class="mr-1" small :color="wsACCENT">mdi-file</v-icon>
            {{ video.poster_name }}
          </h5>
          <v-btn @click="displayImageUpload = true" class="noCaps" :color="wsACCENT" text > {{ $t('Change') }}</v-btn>
        </div>
        <v-img height="350" :src="video.poster" />

      </div>

    </div>

    <v-sheet
        :color="sideInfoColor"
        :style="`border: 1px solid ${wsACCENT}`"
        class=" pa-4 mt-3 wsRoundedLight">

      <div v-if="navigation === 'video'" style="width: 100%">
        <div class="d-flex justify-space-between align-center mb-3">


          <h5 class="mb-3">
            <v-icon :color="wsACCENT"  class="mr-2">mdi-information-outline</v-icon>
            {{ $t('ChooseVideoQuality') }}
          </h5>
          <div v-if="!displayVideoUpload && !video.is_converting">
            <v-btn @click="displayEditFormats" :color="wsACCENT" class="noCaps" text >
              <v-icon v-if="!editFormats" class="mr-2">mdi-pencil</v-icon>
              {{ !editFormats ? $t('Edit') : $t('Cancel') }}
            </v-btn>
          </div>
          <h4 v-if="video.is_converting" class="text-center mb-3">
            {{ $t('ConvertingInProcess') }}
          </h4>

        </div>

        <div class="d-flex flex-wrap justify-space-between">
          <div v-for="format in availableFormats"
               :key="format"
               class="d-flex flex-nowrap  align-center mr-3"
          >

            <v-btn @click="selectFormat(format , !selectedFormats.includes(format))"
                   :color="selectedFormats.includes(format) ?  wsACCENT : 'grey' "
                   class="noCaps"
                   text
                   :disabled="(!displayVideoUpload && !editFormats) || videoLoading"
            >
              <v-icon class="mr-1">{{ selectedFormats.includes(format) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}</v-icon>
              <h5>
                {{ format !== 9999 ? (format + 'p') : $t('Original') }}
              </h5>

            </v-btn>

          </div>
        </div>

        <div v-if="!displayVideoUpload && editFormats">
          <v-btn :disabled="selectedFormats.length === 0" block @click="reformatQuality" :color="wsACCENT" class="noCaps my-3" outlined >
            {{ $t('ReformatVideoQuality') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>


        <h5   v-if="!video.is_converting" class="font-weight-medium mt-3" > {{ $t('VideoConverterInstruction') }}</h5>
        <h5 v-else class="font-weight-medium mt-3" > {{ $t('ConvertingInProcessInstruction') }}</h5>

        <v-progress-linear v-if="video.is_converting" rounded class="mt-3" size="38" indeterminate :color="wsACCENT" />

      </div>
      <div v-if="navigation === 'image'">
        <h5 class="font-weight-medium" >Завнтажте зображення для вашого відео в форматі JPG або JPEG або пропустіть цей крок та зберіжіть налаштування</h5>
      </div>
      <div v-if="navigation === 'subtitles'" >
        <div class="d-flex justify-space-between mb-5">
          <h5 class=""> {{ $t('AddSubtitlesToYourVideo') }}</h5>
          <v-btn
              v-if="displayUploadSubtitles"
              @click="displayUploadSubtitles = false"
              :color="wsACCENT"
              class="noCaps"
              text
          >
            {{ $t('Cancel') }}
          </v-btn>
        </div>

        <div v-if="!displayUploadSubtitles">
          <template v-for="(subtitle , i ) in video.subtitles">
            <div :key="i"  class="d-flex justify-space-between">
              <div>
                <h4> {{ LANGUAGE_TEXT(subtitle.lang) }} </h4>
                <h5>{{   subtitle.name }}</h5>
              </div>
              <v-btn @click="deleteSubtitle(subtitle.uuid , i)" icon :color="wsACCENT">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-divider :style="`border-color : ${ wsBACKGROUND }`" :key="i + 'divider'" class="my-3" />
          </template>

          <v-btn @click="displayUploadSubtitles = true; entityData = {}" block outlined :color="wsACCENT"  class="noCaps mt-5">
            <v-icon>mdi-plus</v-icon>
            {{ $t('AddSubtitles') }}
          </v-btn>
        </div>

        <div v-else>
          <ws-select
              v-model="entityData.lang"
              :items="LANGUAGES_SELECT"
              :label="$t('Language')"
              autocomplete
          />
<!--          :formats="SUBTITLES_FORMATS"-->
          <ws-file-uploader
              :disabled="!entityData.lang"
              @success="uploadSubtitle"

              browser
              public
              class="mt-5"
          />
        </div>


      </div>

    </v-sheet>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "wsVideoEditor",
  mixins : [languagesList],
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : ''
    },
    isPublic : {
      type : Boolean,
      default : false
    },
    superadmin : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {

      editFormats : false,
      videoLoading : false,
      bufferFormats : [],
      selectedFormats : [ 360,480,720 ],
      availableFormats : [ 240,360,480,720,1080,9999 ],
      displayCancelButton : false,
      displayVideoUpload : true,
      displayImageUpload : true,
      displayUploadSubtitles : false,
      navigation : 'video',
      video : {},
      entityData : {},
    }
  },
  computed : {
    convertVideoCondition() {
      if ( !this.selectedFormats) {
        return false
      }
      if ( Array.isArray(this.selectedFormats) ) {
        return this.selectedFormats.length > 0
      }

      return false
    },
    sideInfoColor() {
      // if ( this.navigation === 'video' && this.selectedFormats.length === 0 ) {
      //   return "#eac0c0"
      // }
      return this.wsLIGHTCARD
    },
    sideInfoBorderColor() {
      if ( this.navigation === 'video' && this.selectedFormats.length === 0 ) {
        return "#eac0c0"
      }
      return this.wsACCENT
    },
    navigationItems() {
      return [
        { text : this.$t('Video') , value : 'video' },
        { text : this.$t('Image') , value : 'image' },
        { text : this.$t('Subtitles') , value : 'subtitles' }
      ]
    }
  },
  watch: {

    value() {
      if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.video)) ) {
        this.video = JSON.parse(JSON.stringify(this.value))
        if ( this.video.qualities ) {
          this.selectedFormats = this.video.qualities
        }
        this.checkData()
      }
    }
  },
  methods : {
    ...mapActions('upload' , [
        'UPDATE_VIDEO_FORMATS',
        'GET_USER_FILE_INFO',
        'ADD_VIDEO_SUBTITLE',
        'DELETE_VIDEO_SUBTITLE'
    ]),
    async deleteSubtitle( uuid , index ) {
      let result = await this.DELETE_VIDEO_SUBTITLE(uuid)
      if (!result ) {
        return
      }
      this.video.subtitles.splice(index , 1)
      this.notify(this.$t('SubtitlesDeleted'))

    },
    async uploadSubtitle($event) {
      this.entityData.video_id = this.video.uuid
      this.entityData.file_id = $event.uuid
      let result = await this.ADD_VIDEO_SUBTITLE(this.entityData)
      if ( !result ) {
        return
      }
      this.video.subtitles.push(result)
      this.displayUploadSubtitles = false
      this.notify(this.$t('FileUploaded') , 'success')
    },

    reformatQuality() {
      if ( this.selectedFormats.length === 0 ) {
        return this.notify(this.$t('VideoConverQualitiesNotSelected') , 'warning')
      }
      let data = {
        qualities : this.selectedFormats.sort((a, b) => { return b - a }),
        file_uuid : this.video.uuid
      }
      let result = this.UPDATE_VIDEO_FORMATS(data)
      if ( !result ) {
        return
      }

      this.editFormats = false
      this.video.is_converting = true
      this.notify(this.$t('VideoConversionProcessStarted') )
    },
    displayEditFormats() {
      if ( !this.editFormats) {
        this.bufferFormats = JSON.parse(JSON.stringify(this.selectedFormats))
        this.editFormats = true
      } else {
        this.editFormats = false
        this.selectedFormats = this.video.qualities || []
      }
    },

    selectFormat(format , value ) {
      if ( (!this.displayVideoUpload && !this.editFormats  )  ) {
        return
      }
      if ( this.editFormats && this.bufferFormats.length > 0 ) {
        if ( parseInt(format) > Math.max(...this.bufferFormats)  ) {
          return this.notify(this.$t('VideoConverterBiggerFormatError') , 'warning')
        }
      }

      if (!value ) {
        this.selectedFormats = this.selectedFormats.filter( el=> el !== format)
      } else{
        if ( !this.selectedFormats.includes(format) ) {
          this.selectedFormats.push(format)
        }
      }
    },
    uploadFile(file) {
      if ( this.navigation === 'video' ) {
        file.poster = this.video.poster
        file.poster_name = this.video.poster_name
        file.poster_file_id = this.video.poster_file_id
        this.video = file
        this.navigation = 'image'
        // this.displayVideoUpload = false
        this.save(false)
        return
      }
      if ( this.navigation === 'image' ) {
        this.video.poster = file.url
        this.video.poster_name = file.name
        this.video.poster_file_id = file.uuid
        this.video = Object.assign({},this.video)
        // this.displayImageUpload = false
        this.save()
      }
    },

    save(close = true) {
      this.video.close = close
      this.$emit('input',this.video)
    },
    cancel() {
      this.$emit('cancel')
    },
    checkData() {
      if ( this.video.uuid ) {
        this.displayVideoUpload = false
        this.displayCancelButton = true
      }
      if ( this.video.poster ) {
        this.displayImageUpload = false
      }
    }
  },

  async mounted() {

    if ( this.value ) {
      this.video = this.value

      if (this.video.uuid ) {
        let result = await this.GET_USER_FILE_INFO(this.video.uuid)
        if ( result ) {
          this.video = result
        }

      }

      if ( this.video.qualities ) {
        this.selectedFormats = this.video.qualities || []
      }
      this.checkData()
    }
  }
}
</script>

<style scoped>

</style>