<template>

    <v-sheet
        :color="$store.state.notification_color"
        dark
        class="d-flex justify-space-between pa-3 notification"
        :style="!top ? 'bottom: 0;' : 'top : 0'"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
    >
      <v-icon>{{ $store.state.notification_icon || 'mdi-information' }}</v-icon>

      <div style="max-width: 600px ">
        <h5 class="notranslate" style="margin-top: 4px"> {{  text  }} </h5>
      </div>
      <v-icon class="pointer"  @click="$store.state.notification = false"  >mdi-close</v-icon>
    </v-sheet>

</template>

<script>
export default {
  name: "wsNotification",
  props : {
    text : {
      type : String
    },
    top : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      pause : false
    }
  },
  methods : {
    mouseOver() {
      if (!this.pause) {
        this.pause = true
      }
    },
    mouseOut() {
      this.pause = false
        setTimeout(() => {
          if ( !this.pause ) {
            this.$store.state.notification = false
          }
        },1000)

    }
  },
  mounted() {
    setTimeout(() => {
      if ( !this.pause ) {
        this.$store.state.notification = false
      }
    },2500)
  }
}
</script>

<style scoped>
.notification {
  position : fixed;
  padding: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2147483647;
}
</style>