import VueSocketIO from "vue-socket.io";

export default function makeSocket( _, wss ) {

    return new VueSocketIO({

        debug: true,
        connection: wss,
        protocol : 'echo-protocol',
        token : 'aw0*^%9de4_329&#52p',
    })

}