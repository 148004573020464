<template>
  <portal to="dialog" :disabled="!portal">
  <v-fade-transition>
    <v-sheet
        v-if="display"
        @click.stop="overlayClick"
        :color="overlay"
        style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 999999999999999"
        class="d-flex align-center "
        :class="[
            { 'justify-center' : !fullscreenAlign },
            { 'justify-end' : fullscreenAlign === 'right' },
            { 'justify-start' : fullscreenAlign === 'left' },
        ]"
    >
      <v-sheet
          light
          @click.stop
          :width="dialogWidth"
          :class="[
              {'fill-height '  :   fullscreen || SM  },
              {'wsRoundedHalf' : !(fullscreen || SM) },
          ]"
          style="max-height: 90%"
          class="justify-space-between overflow-hidden d-flex flex-column"
          elevation="5"

      >

        <!-- HEADER-->
        <!-- Title and Close Button-->
        <div v-if="!blank"
             class="d-flex justify-space-between align-center pb-3 px-6 pt-5" >
          <h3 :style="`color : ${wsDARKER}; font-weight: 600; font-size: ${!SM ? 20 : 16}px`">
            <v-icon v-if="!!titleIcon" :color="wsACCENT">{{ titleIcon }}</v-icon>
            {{  title  }}
          </h3>
          <v-btn
              @click="display = false"
              class="mr-n2"
              :color="wsDARKER"
              icon
          >
            <v-icon >mdi-close</v-icon>
          </v-btn>


        </div>

        <!-- CONTENT-->
        <div style=" position: relative"
             :style="notCentered ? 'height: 100%;' :  'min-height: 150px;' "
             class="overflow-y-auto pb-8 px-6" >
          <!-- Subtitle-->
          <h3 v-if="subtitle"
              :style="`font-weight: 400; color : ${wsDARKER};
              font-size: ${!SM ? 18 : 14}px;
              ${!SM ? 'max-width : 85%' : ''}`"
              class="mb-5"
          >
            {{  subtitle  }}
          </h3>
          <!-- Content-->
          <slot v-if="!displayConfirmDelete" name="default"></slot>
          <template v-else>
            <h4 class="text-center font-weight-medium">{{ deleteConfirmationText }}</h4>
            <h5 v-if="confirmDeleteCode" class="text-center mt-3  font-weight-medium">{{ $t('DeleteConfirmationCodeDescription') }}:</h5>
            <h4 v-if="confirmDeleteCode" class="text-center mt-1 mb-5">{{ confirmDeleteCode }}</h4>
            <ws-text-field
                v-if="confirmDeleteCode"
                v-model="deleteConfirmationCode"
                :placeholder="$t('EnterConfirmationCode')"
                clearable
                :error="!!deleteCodeError"
                :error-messages="deleteCodeError"
                @input="deleteCodeError = null"
                :hide-details="false"
            />

          </template>

        </div>


        <!-- BUTTONS -->
        <v-sheet v-if="!noNavigation" color="transparent" style="position:relative;" class="pt-3 pa-6" >
          <!-- Transparent Overlay for Overflow -->
          <v-sheet
              style="position:absolute; left: 0; right: 0; top: -30px;
              background: linear-gradient(to bottom, transparent, white );"
              height="30"
          />
          <!-- Buttons -->
          <div v-if="!displayConfirmDelete && !noNavigation" class="d-flex align-center " :class="showDelete ? 'justify-space-between' : 'justify-end'" >
            <v-btn  v-if="showDelete"  @click="displayConfirmDelete=true"   class=" noCaps" dark  :color="wsWARNING"  >
              <v-icon small class="mr-1">mdi-delete</v-icon>
              {{ $t('Delete') }}
            </v-btn>
            <div v-if="!hideButtons"  class=" d-flex justify-end">
              <!-- Cancel button  -->
              <ws-button
                  @click="cancelButtonAction"
                  :label="cancelText"
                  outlined
                  :disabled="loading"
                  class="mr-2"
              />
              <ws-button
                  @click="$emit('save')"
                  :label-raw="saveText"
                  :loading="loading"
              />
            </div>
          </div>
          <div v-else-if="!noNavigation"  >
            <div  class="d-flex justify-end">
              <v-btn height="40"   @click="!closeDelete ? displayConfirmDelete = false : display = false" :disabled="loading" class=" noCaps" dark  :color="wsDARKER" >{{ $t('Cancel') }}</v-btn>
              <v-btn   @click="deleteAction"  :loading="loading" class=" noCaps" text >{{ $t('Delete') }}</v-btn>
            </div>
          </div>
        </v-sheet>
        <v-sheet color="transparent" height="1px" v-else />



      </v-sheet>

    </v-sheet>
  </v-fade-transition>
  </portal>


</template>

<script>


export default {
  name: "wsDialog",
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    overlayColor : {
      type : String
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    fullscreen : {
      type : Boolean,
      default : false
    },
    blank : {
      type : Boolean,
      default : false
    },
    hideButtons : {
      type : Boolean,
      default : false,
    },
    loading : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      default : '',
    },
    subtitle : {
      type : String,
      default : '',
    },
    saveText : {
      type : String,
      default : 'Зберегти',
    },
    cancelText : {
      type : String,
      default : 'Cancel',
    },
    showSave : {
      type : Boolean,
      default : true,
    },
    showDelete : {
      type : Boolean,
      default : false,
    },
    cancelAction : {
      type : Boolean,
      default : false,
    },
    width : {
      type : String,
      default : '600',
    },
    noNavigation : {
      type : Boolean,
      default : false,
    },
    displayConfirmDelete : {
      type : Boolean,
      default : false,
    },
    closeDelete : {
      type : Boolean,
      default : false,
    },
    confirmDeleteText: {
      type : String,
      default : null,
    },
    confirmDeleteCode: {
      type : String,
      default : null,
    },
    persistent : {
      type : Boolean,
      default : false,
    },
    fullscreenWidth : {
      type : String
    },
    fullscreenAlign : {
      type : String
    },
    notCentered : {
      type : Boolean,
      default : false,
    },
    titleIcon : {
      type : String
    },
    portal : {
      type : Boolean,
      default : false,
    }
  },

  data() {
    return {
      display : false,
      deleteConfirmationText : '',
      deleteConfirmationCode : null,
      deleteCodeError : null,
      overlay : ''
    }
  },
  computed : {
    dialogWidth() {
      if ( this.fullscreen && this.fullscreenWidth ) {
        return this.fullscreenWidth
      }
      return !(this.fullscreen || this.SM)  ? this.width : '100%'
    },
    getTotalClass() {
      if ( this.displayShow(['sm'])) {
        return 'fill-height '
      }
      return !this.blank ? 'px-6' : 'pa-1'
    }
  },
  watch : {
    value(val) {
      if ( this.value !== this.display) {
        this.display = val
      }
    },
    display(val) {
      if (this.value !== this.display) {
        this.$emit('input',val)
      }
      if ( val === false ) {  this.$emit('close') }
    },
  },
  methods : {
    deleteAction() {

      if ( this.confirmDeleteCode && this.deleteConfirmationCode !== this.confirmDeleteCode) {
        this.deleteCodeError = this.$t('WrongDeleteConfirmationCode')
        return
      }

      this.$emit('delete');
    },
    cancelButtonAction() {
      if ( this.cancelAction )  {
        this.$emit('cancel')
        return
      }
      this.display = false
    },
    overlayClick() {
      if ( this.persistent ) {
        return
      }
      this.display = false
    }
  },
  beforeMount() {
    this.overlay = this.overlayColor || this.wsACCENT
    if ( this.overlayOpacity ) {
      this.overlay +=  this.overlayOpacity
    } else {
      this.overlay += "bc"
    }
    if ( this.overlayColor ) {
      this.overlay = this.overlayColor
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = JSON.parse(JSON.stringify(this.value))
    }
    this.deleteConfirmationText = this.confirmDeleteText ? this.confirmDeleteText : this.$t('PleaseConfirmDeleteAction')
  }
}
</script>

<style scoped>

</style>