<template>
  <div
      v-if="section.isHtml"
      v-html="section.text"
      class="htmlSection py-2"
      :style="!!fontSize ? `font-size: ${fontSize}px` : null"
  />
</template>

<script>

export default {
  name: "editorSectionHtml",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    lang : {
      type : String,
      default : 'ua',
    },
    placeholder : {
      type : String
    },
    fontSize : {
      type : String,
    }
  }
}
</script>

<style scoped>
.htmlSection {
  font-size: 14px;
  font-weight: 400;
}
</style>