export default {
    data() {
        return {
            INITIAL_CONNECT_INTERVAL_ID : {},
            CONNECTION_INTERVAL_TIME : 5000
        }
    },
    methods: {
        async EMIT(action , data = null , callback) {

            let result = await this.$store.dispatch('socket/EMIT', {
                action: action,
                data: data ?? {}
            })
            if ( result && callback )  {
                callback.call(this,result)
            }

            return result
        },

        async CONNECT(action , data = null , callback) {

            const connect = () => {
                this.EMIT(action, data , callback)
            }
            connect()
            // Function is returning interval id , because it is needed for Disconnect function to clear Interval
            let interval = setInterval(connect , this.CONNECTION_INTERVAL_TIME)
            return interval

        },
        DISCONNECT(intervalId) {
            console.log(`[DISCONNECT : ${intervalId}]`)
            clearInterval(intervalId)
        }
    },
    async mounted() {

        // handle auto CONNECT param
        if (this.$options.connect ) {
            let body = this.$options.connect_data;
            if (typeof body === 'function') {
                body = this.$options.connect_data.call(this)
            }
            // Setting await , because CONNECT is async function and our interval id will be returned as promise
            this.INITIAL_CONNECT_INTERVAL_ID = await this.CONNECT(
                this.$options.connect ,
                body ,
                this.$options.connect_callback
            )
        }

        // handle auto INIT param
        if (this.$options.init ) {
            let body = this.$options.init_data;
            if (typeof body === 'function') {
                body = this.$options.init_data.call(this)
            }
            // Setting await , because CONNECT is async function and our interval id will be returned as promise
            await this.EMIT(
                this.$options.init ,
                body ,
                this.$options.init_callback
            )
        }


    },
    beforeDestroy() {
        if (this.$options.connect ) {
            this.DISCONNECT(this.INITIAL_CONNECT_INTERVAL_ID)
        }
    }
}