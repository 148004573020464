<template>
  <div class="d-flex" :class="centered ? 'align-center' : ''">
    <v-icon small :color="color" class="mr-1">{{ icon }}</v-icon>
    <h5 :style="`color: ${color !== wsDARKER ? color : ''}`"
        :class="elementClass">{{ text }}</h5>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "wsChip",
  props : {
    icon : {
      type : String,
      default : 'mdi-cog'
    },
    centered : {
      type : Boolean,
      default : false
    },
    text : {
      type : [String,Number],
      default : ''
    },
    color : {
      type : String,
      default : ''
    },
    bold : {
      type : Boolean,
      default : false
    },
    noWrap : {
      type : Boolean,
      default : false,
    }
  },
  computed : {
    elementClass() {
      let item = ''

      if ( this.noWrap ) {
        item += 'text-no-wrap'
      }

      return item
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>