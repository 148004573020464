<template>
  <div class="d-flex align-center">
    <v-btn v-if="isBool" @click.stop="selectAction"
           :color="checkBoxColor"
           :small="small" icon class="mr-1">
      <v-icon  :small="small">{{ checkBoxIcon }}</v-icon>
    </v-btn>
    <div @click="selectAction" class="pointer" :style="error ? `color : ${wsWARNING} !important ` : null">
      <slot name="default"> <h5 v-if="label">{{ label }}</h5></slot>
    </div>
  </div>


</template>

<script>
export default {
  name: "wsCheckBox",
  props : {
    isBool : {
      type : Boolean,
      default : true
    },
    small : {
      type : Boolean,
      default : true
    },
    semiSelected : {
      type : Boolean,
      default : false
    },
    color : {
      type : String,
    },
    label : {
      type : String,
    },
    value : {},
    preventSelect : {
      type : Boolean,
      default : false
    },
    error : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selected : false
    }
  },
  computed : {
    checkBoxIcon() {

      if ( this.semiSelected ) {
        return 'mdi-checkbox-intermediate'
      }
      return this.selected ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'
    },
    checkBoxColor() {
      if ( this.error ) {
        return this.wsWARNING
      }
      return this.selected ? 'green lighten-2' : (this.color || 'grey darken-1')
    }
  },
  watch: {
    value(value) {
      if ( this.selected !== value) {
        this.selected = value
      }
    }
  },

  methods : {
    selectAction() {
      if ( this.preventSelect ) {
        this.$emit('prevent')
        return
      }
      this.selected = !this.selected
      this.$emit('input',this.selected)
    }
  },
  mounted() {
    if (this.isBool && this.value ) {
      this.selected = this.value
    }
  }
}
</script>

<style scoped>

</style>