<template>
  <dash-page-new
      :title="server"
      subtitle="Current server state"
      root="Dashboard"
      icon="mdi-server"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >
    <template #header_action></template>

    <template #default>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Server Details</h3>
        <h4 class="mt-3"></h4>
      </v-sheet>

      <v-sheet
          :color="wsDARKER"
          class="pa-6 wsRoundedHalf mt-6"
          dark
      >
        <div v-if="Object.keys(serverData).length === 0">
          <code>Server is not processed by polling system</code>
        </div>

        <div v-for="(value,key) in serverData" :key="key">
          <code> <b>{{ key }}</b> : {{ value }} </code>
        </div>
      </v-sheet>

      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <ws-navigation
            v-model="navigation"
            :items="navigationItems"
            no-number
        >
          <template #item="{item}">
            <log-reader
                :file-name="item.value"
                :server="server"
            />
          </template>
        </ws-navigation>
      </v-sheet>
    </template>

    <template #dialog>
      <ws-dialog
          v-model="displayFileRelocationDialog"
          @save="executeFileRelocation"
          title="File relocation"
          save-text="Save"
          cancel-text="Cancel"
      >
        <ws-select
            v-model="entityData.selectedCloudServers"
            :items="cloudServers"
            label="Source servers"
            placeholder="Select, from where to take"
            multiple
        />
      </ws-dialog>
    </template>
  </dash-page-new>
</template>

<script>

import {mapActions} from "vuex";
import logReader from "@/components/dashboard/states/UI/logReader.vue";

export default {
  name: "serverStates",
  components: {
    logReader,
  },
  props: {
    server: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentDate: null,
      navigation: "",
      privateBusinessAlias: null,
      entityData: {},
      serverData: {},
      cloudServers: [],
      selectedCloudServers: [],
      isPrivateCloud: false,
      displayEditServerDialog: false,
      displayFileRelocationDialog: false,
      logFiles: [],
    }
  },
  computed: {
    navigationItems() {
      let items = [];

      this.logFiles.forEach(item => {
        items.push({text: item, value: item});
      });

      return items;
    }
  },
  watch: {
    server() {
      this.initData()
    }
  },
  methods: {
    ...mapActions('servers', [
      'GET_SERVERS_HEALTH',
      'GET_SERVER_CONFIG',
      'EDIT_SERVER',
      'GET_NON_BUSINESS_CLOUD_SERVERS',
      'EXECUTE_FILE_RELOCATION',
    ]),

    ...mapActions("logs", [
      'GET_LOG_FILES',
    ]),

    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = this.formatNumber(date.getMonth() + 1);
      const day = this.formatNumber(date.getDate());

      this.currentDate = `${year}-${month}-${day}`;
    },
    formatNumber(number) {
      return number.toString().padStart(2, '0');
    },

    async openEditServer() {
      let data = await this.GET_SERVER_CONFIG(this.server);
      if (!data) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.entityData = data;
      this.displayEditServerDialog = true
    },

    async editServer() {
      let result = await this.EDIT_SERVER(this.entityData);
      if (!result) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.displayEditServerDialog = false;
    },

    async openFileRelocation() {
      let servers = await this.GET_NON_BUSINESS_CLOUD_SERVERS();
      if (!servers) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      Object.keys(servers).forEach(server => {
        this.cloudServers.push({
          text: servers[server].alias,
          value: servers[server].alias,
        });
      });

      this.displayFileRelocationDialog = true
    },

    async executeFileRelocation() {
      let list = [];
      Object.keys(this.cloudServers).forEach(server => {
        list.push(this.cloudServers[server].value);
      });

      let result = await this.EXECUTE_FILE_RELOCATION({
        server_alias: this.server,
        business_alias: 'coco_style',//this.privateBusinessAlias,
        source_servers: list,
      });
      if (!result) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.displayEditServerDialog = false;
    },

    async initData() {
      if (!this.currentDate || this.currentDate.length === 0) {
        this.getCurrentDate();
      }

      let data = await this.GET_SERVERS_HEALTH();
      if (!data) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      let logFiles = await this.GET_LOG_FILES(this.server);
      if (!logFiles) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      Object.keys(logFiles).forEach(logFile => {
        let filePath = logFiles[logFile].replace('%s', this.currentDate);

        this.logFiles.push(filePath);
      });

      Object.keys(data).forEach(server => {
        if (server === this.server) {
          let serverData = {
            "Server": server,
            "Type": data[server].type,
            "CPU load": data[server].cpu.load.value + data[server].cpu.load.unit,
            "CPU avg ": data[server].cpu.avg.value,
            "CPU temp: ": data[server].cpu.temp.value + data[server].cpu.temp.unit,
            "HDD": data[server].hdd.load.value + data[server].hdd.load.unit
          };

          if (data[server].type === 'cloud') {
            serverData['Is private'] = 'alias' in data[server].cloud && data[server].cloud.alias ? data[server].cloud.alias : 'no';
            serverData['Converters online'] = data[server].cloud.converter.count.online;
            serverData['Converters busy'] = data[server].cloud.converter.count.working;

            if ('business_alias' in data[server] && data[server].business_alias) {
              this.isPrivateCloud = true;
              this.privateBusinessAlias = data[server].business_alias;
            }
          }

          // Show warnings and errors
          serverData["Warnings"] = data[server].info.length > 0 ? "" : "no";
          serverData["Fatal errors"] = data[server].fatals.length > 0 ? "" : "no";
          data[server].info.forEach(server => {
            serverData['Warnings'] += server + '<br>';
          })
          data[server].fatals.forEach(server => {
            serverData['FATAL ERRORS'] += server + '<br>';
          })
          // --

          this.serverData = serverData;
        }
      });
    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>