<template>
<dash-page-new
    title="socket_test"
    icon="mdi-connection"
>
  <h1 class="mb-6">This is socket test page : {{ uuid }}</h1>

  <!-- Control buttons-->
  <ws-button class="mr-2" @click="getOneServer"  label="Single Request " />
  <ws-button @click="connectToServer" :color="wsACCENT" label="Manual Connect"></ws-button>

  <v-row>
    <v-col cols="6">

      <!-- Auto Connect-->
      <v-sheet class="pa-6 wsRoundedLight mt-6" dark>
        <h4 class="mb-3">Auto connect on init reply (Received Replies : {{ autoConnectReplies }})</h4>

        <v-sheet class="pa-3 my-4" style="border : 1px solid white">
          <h4>Reply:</h4>
          <code class="mt-5"> {{ socketReply }}</code>
        </v-sheet>

        <h5 class="font-weight-regular">
          - Autoconnect is triggered onMount automaticaly,  if your vue component has "connect" param
        </h5>
        <h5 class="font-weight-regular">
          - you can handle response from server in "connect_callback" parametr of your vue component, and set what to do with server reply, it will be triggered every 5 seconds
        </h5>
        <h5 class="font-weight-regular">
          - If you need to pass some data to server in your connect command you use "connect_data" property of your vue componenet
        </h5>
        <h5 class="font-weight-regular mb-3">
          - If you need to use your component variables in "connect_data" - you need to write it as function (the same as computed properties)
        </h5>



      </v-sheet>
      <!-- Manual Connect-->
      <v-sheet class="pa-6 wsRoundedLight mt-6" dark>
        <h4 class="mb-3">Manual connect reply (connection {{!manualConnectionIntervalId ? ' not ' : ''}} established , received replies : {{ manualConnectReplies }})</h4>

        <v-sheet class="pa-3 my-4" style="border : 1px solid white">
          <h4>Reply:</h4>
          <code class="mt-5"> {{ socketReply3 }}</code>
        </v-sheet>

        <h5>manualConnectionIntervalId {{ manualConnectionIntervalId }} </h5>

        <h5 class="font-weight-regular">
          - your can trigger CONNECT function manualy from your code. CONNECT function returns interval Id , which you need later to DISCONECT, so write it in separate Variable
        </h5>
        <h5 class="font-weight-regular">
          - If you trigger manually CONNECT function - dont forget to write DISCONNECT in beforeDestroy() function!
        </h5>



      </v-sheet>

    </v-col>

    <v-col cols="6">

      <!-- Auto Single Request -->
      <v-sheet class="pa-6 wsRoundedLight mt-6" dark>
        <h4 class="mb-3">Auto single request reply</h4>

        <v-sheet class="pa-3 my-4" style="border : 1px solid white">
          <h4>Reply:</h4>
          <code class="mt-5"> {{ socketReply4 }}</code>
        </v-sheet>

        <h5 class="font-weight-regular">
          - Auto request is triggered onMount automaticaly,  if your vue component has "init" param, this is one time signle request, to recieve one response
        </h5>
        <h5 class="font-weight-regular">
          - you can handle response from server in "init_callback" parametr of your vue component, and set what to do with server reply, it will be triggered once on startup of component
        </h5>
        <h5 class="font-weight-regular">
          - If you need to pass some data to server in your init command you use "init_data" property of your vue componenet
        </h5>
        <h5 class="font-weight-regular">
          - If you need to use your component variables in "init_data" - you need to write it as function (the same as computed properties)
        </h5>


      </v-sheet>
      <!-- Manual Single Request -->
      <v-sheet class="pa-6 wsRoundedLight mt-6" dark>
        <h4 class="mb-3">Manual single request reply (Received replies : {{ singleRequestReplies }})</h4>

        <v-sheet class="pa-3 my-4" style="border : 1px solid white">
          <h4>Reply:</h4>
          <code class="mt-5"> {{ socketReply2 }}</code>
        </v-sheet>


        <h5 class="font-weight-regular">
          - your can emit any request to server using this.EMIT function . it is asynchronous , so dont forget about async await
        </h5>
        <h5 class="font-weight-regular">
          - EMIT function takes next params :
        </h5>
        <h5 class="mt-3">action : <span class="font-weight-regular"> (STRING) this is your command </span></h5>
        <h5>data : <span class="font-weight-regular"> (OBJECT)this is data which you send to server for this command </span></h5>
        <h5>callback : <span class="font-weight-regular">(FUNCTION) this is callback, triggered after succes, dont use it, instead use result = await this.EMIT </span></h5>

      </v-sheet>

    </v-col>
  </v-row>





</dash-page-new>
</template>

<script>

export default {
  name: "DashSocketTest",
  props : {
    uuid : {
      type : String,
      default : '2384023049324'
    }
  },
  // if you want to automaticaly connect to some command and receive data online from it you use this paramater
  connect : 'servers/get_all',
  // here you set what to do with connection result, it will be called every 5 seconds
  connect_callback(result) {
    this.socketReply = this.COPY(result)
    this.autoConnectReplies++
  },
  // Here you set connect data , if you nee dto send to server some data in your socket request
  // If you need to use componets variables this property must be function (like computed property),
  // if no need to take components variables, you can set it like regular var
  // like this:  connect_data : { id : 123 }
  connect_data() {
    return {
      uuid : this.uuid,
      something : 'else',
      very : 'good'
    }
  },

  // if you want to automaticaly send single request and receive data you use this param
  init : 'servers/get_all',
  // you set your function as callback for init command (same as with connect command)
  init_callback(result) {
    this.socketReply4 = result
  },
  init_data : { server : 'very good'},
  data() {
    return {
      socketReply : {pedro : 'popa'},
      socketReply2 : {},
      socketReply3 : {},
      socketReply4 : {},
      autoConnectReplies : 0,
      manualConnectReplies : 0,
      singleRequestReplies : 0,
      manualConnectionIntervalId : null,
      pedro : 'kaka'
    }
  },
  methods : {
    async connectToServer() {
      this.manualConnectionIntervalId = await this.CONNECT('servers/get_all' , {zhigan : 'limon'} , (result) => {
        this.socketReply3 = result
        this.manualConnectReplies++
      })
    },
    async getOneServer() {
      let result = await this.EMIT("servers/get_all")
      if ( !result ) {
        this.notify('ERROR')
      }
      this.socketReply2 = result
      this.singleRequestReplies++
    }
  },
  mounted() {

  },
  beforeDestroy() {
    if ( this.manualConnectionIntervalId )
      this.DISCONNECT(this.manualConnectionIntervalId)
  }
}
</script>

<style scoped>

</style>