async function handleResult(baseURL, route, params, file) {
    route = baseURL + route
    if (!file) {

        let out = await fetch(route, params).then((resp) => resp.json()).catch((error) => {
            console.log(error)
        })
        if (out.result) {
            return out.data || true
        } else {

            if (out.error === "Expired token") {

                let result = await refreshToken(baseURL)
                if (!result) {
                    throw new Error('refresh_expired')
                }

                params.headers.Authorization = "Bearer " + result

                let out = await fetch(route, params).then((resp) => resp.json()).catch((error) => {
                    console.log(error)
                })

                if (out.result) {
                    return out.data || true
                }
                if (out.error) {
                    throw new Error(out.error)
                }

            } else {
                throw new Error(out.error)
            }

        }
        return out.result ? (out.data || true) : false

    } else {

        let out = await fetch(route, params).then((resp) => resp.blob()).catch((error) => {
            console.log(error)
        })
        if (out.type === 'application/json') {
            let data = JSON.parse(await out.text());

            if (data.error === "Expired token") {

                let result = await refreshToken(baseURL)
                if (!result) {
                    throw new Error('refresh_expired')
                }
                params.headers.Authorization = "Bearer " + result

                let out = await fetch(route, params).then((resp) => resp.blob()).catch((error) => {
                    console.log(error)
                })
                if (out.type === 'application/json') {
                    let data = JSON.parse(await out.text());
                    throw new Error(data.error)
                }

                return out

            } else {
                throw new Error(data.error)
            }
        }
        return out
    }
}

async function refreshToken(route) {
    route = route + 'user/refresh'
    let params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`
        },
    };
    console.log('refreshing')
    let result = await fetch(route, params).then((resp) => resp.json()).catch()
    result = await result;
    console.log(result)
    if (!result.result) {
        return false
    }

    localStorage.setItem('access_token', result.data.access)
    localStorage.setItem('refresh_token', result.data.refresh)

    return result.data.access

}

export default function makeAjaxNew(baseURL, i18n) {

    return async function ajax({route, method, token, business, returnFile, headers = '', body = ''}, file = null) {

        if (!headers) {
            headers = {}
        }

        if (token !== 'public') {
            headers = headers ? {
                ...headers,
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            } : {'Authorization': `Bearer ${localStorage.getItem('access_token')}`};
        }
        if (business) {
            headers = {
                ...headers,
                'Business': business
            }
        }

        let params = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Lang': i18n.locale,
                ...headers
            },
        };


        if (body) {
            if (body.fileFormData) {

                let data = new FormData()
                data.append('file', body.fileFormData)

                if (body.data) {
                    Object.keys(body.data).forEach((paramName) => {
                        data.append(paramName, body.data[paramName])
                    })
                }

                delete params.headers['Content-Type']
                // params.headers['Content-Type'] = null
                params.body = data

            } else {
                console.log(body);
                body = JSON.stringify(body);
                body = body.replace(/’/gi, '\'');
                body = unescape(encodeURIComponent(body));
                params['body'] = body;
            }
        }

        if (returnFile) {
            file = true
        }

        return await handleResult(baseURL, route, params, file);


    }

}

