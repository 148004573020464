var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"pb-4 wsHoverLight",attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search,"items-per-page":4,"footer-props":{ itemsPerPageOptions : [25,50,100,-1]},"hide-default-header":"","hide-default-footer":"","disable-pagination":_vm.disablePagination,"dense":_vm.dense},on:{"current-items":function($event){return _vm.$emit('current-items' , $event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [(!_vm.noHeader)?_c('wsTableHeader',{attrs:{"props":props,"on":on}}):_vm._e()]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return [_vm._t("item",[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{key:index,class:[{ 'pointer' : _vm.rowAction }],style:(_vm.getRowColor(hover, item.uuid)),on:{"click":function($event){return _vm.action(item)},"contextmenu":_vm.openContextMenu}},_vm._l((_vm.headersFiltered),function(column){return _c('td',{key:'column' + index + column.value,style:(("border-color: " + _vm.wsBACKGROUND)),attrs:{"width":column.width}},[_vm._t('item.' + column.value,[_vm._v(" "+_vm._s(item[column.value])+" ")],{"item":item,"index":index,"isSelected":_vm.isSelectedRow(item.uuid)})],2)}),0)]}}],null,true)})],{"item":item,"index":index,"isSelected":_vm.isSelectedRow(item.uuid),"contextAction":_vm.openContextMenu})]})],2),_c('tfoot',[_c('tr',[_c('td',{staticClass:"pa-0 ma-0",staticStyle:{"height":"1px !important"},attrs:{"height":"1px","colspan":_vm.headers.length}},[_c('v-divider',{staticClass:"mb-3",style:(("border-color: " + _vm.wsBACKGROUND))})],1)])])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [(!_vm.disablePagination)?_vm._t("footer",[(_vm.displayPaginationCondition(props))?_c('v-sheet',{staticClass:"py-3 justify-end d-flex align-center",attrs:{"color":"transparent"}},[_c('h5',{staticClass:"mr-6"},[_vm._v(_vm._s(_vm.$t('RowsPerPage'))+" ")]),_c('ws-select',{staticClass:"mr-6",attrs:{"items":_vm.paginationSelect(props),"width":"100px"},model:{value:(props.options.itemsPerPage),callback:function ($$v) {_vm.$set(props.options, "itemsPerPage", $$v)},expression:"props.options.itemsPerPage"}}),_c('v-btn',{attrs:{"disabled":props.options.page <2,"icon":"","color":_vm.wsACCENT},on:{"click":function($event){props.options.page--}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h5',{staticClass:"mx-4"},[_vm._v(_vm._s(_vm.$t('RowsDisplayed'))+" "+_vm._s(props.pagination.pageStart + 1)+" - "+_vm._s(props.pagination.pageStop)+" із "+_vm._s(_vm.items.length))]),_c('v-btn',{attrs:{"disabled":props.options.page >= props.pagination.pageCount,"icon":"","color":_vm.wsACCENT},on:{"click":function($event){props.options.page++}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-right")])],1)],1):_c('div',[_c('h5',{staticClass:"text-right pr-8"},[_vm._v(" "+_vm._s(_vm.$t('RowsDisplayed'))+" : "+_vm._s(_vm.items.length)+" ")])])]):_vm._e()]}}],null,true)}),(_vm.contextActionsSelect.length > 0)?_c('ft-select',{attrs:{"items":_vm.contextActionsSelect,"expanded":_vm.displayContextMenu,"x":_vm.x,"y":_vm.y,"absolute":""},on:{"input":function($event){_vm.$emit('contextAction' , $event,  _vm.selectedItem); _vm.$emit('itemSelect' , _vm.selectedItem)},"expand":function($event){_vm.displayContextMenu = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }