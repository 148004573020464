<template>
<v-sheet dark class="fill-height d-flex align-center ">
  <h1 class="align-center">Page not found</h1>
</v-sheet>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped>

</style>