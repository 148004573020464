<template>
  <thead>
  <tr :style="`background-color : ${wsLIGHTCARD};`" >

      <th v-for="(header,i) in headersFiltered" :key="i + 'header'"
          :class="header.align ? `text-${header.align}` : ''"
          :style="getColumnStyle(i , header)"
          class="pointer pa-0"
          @click="header.sort !== false ?  on.sort(header.value) : null"
      >
        <v-hover v-slot="{hover}">
          <v-sheet color="transparent" class="d-flex px-5 align-center fill-height"  >
            <span :style="`color : ${hover || sortOn(header.value) ? '' : '#7a7a7a' }`" style="transition: all 0.3s ease">{{  header.text  }}</span>
            <v-sheet v-if="header.sortable !== false" color="transparent" width="20">
              <v-fade-transition>
                <v-icon
                    v-if="hover || sortOn(header.value) "
                    :color="sortOn(header.value)  ? 'black' : '' "
                    :class="[{rotate : sortDesc() && sortOn(header.value) }]"
                    style="transform-origin: center;"
                    small>
                  mdi-arrow-up
                </v-icon>

              </v-fade-transition>
            </v-sheet>
          </v-sheet>
        </v-hover>



      </th>



  </tr>
  </thead>
</template>

<script>
export default {
  name: "wsTableHeader",
  props : {
    props : {
      type : Object,
      default() { return { headers : [] } }
    },
    on : {
      type : Object,
    }
  },
  computed : {
    headersFiltered() {

      let headers = this.props.headers
      if ( headers.length === 0) {
        return []
      }
      headers = headers.filter(el => el.align !== 'd-none')

      return headers

    }
  },
  methods : {
    getColumnStyle(i , header ) {
      let style = `border-color : ${this.wsBACKGROUND};`
      style += 'color : #666A6D; '
      if ( i === 0) {
        style += 'border-radius: 8px 0 0 0  !important;'
      }
      if ( i === this.props.headers.length -1) {
        style += 'border-radius: 0 8px 0 0  !important;'
      }

      if ( header.width ) {
        style += `width : ${header.width}px;`
      }

      return style
    },
    sortOn(header) {
      return this.props.options.sortBy.includes(header)
    },
    sortDesc() {
      return this.props.options.sortDesc[0]
    }
  }
}
</script>

<style scoped>
.rotate {
  transform: rotate(180deg);

}
</style>