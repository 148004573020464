<template>
  <div>

    <v-menu :open-on-hover="true" open-on-click  offset-y transition="slide-y-transition">
      <template v-slot:activator="{attrs,on}">
        <v-sheet
            v-bind="attrs" v-on="on"
            class="d-flex align-center"
            color="transparent"
            rounded v-ripple style="cursor: pointer" >
<!--          <v-icon :color="textColor || wsACCENT">mdi-web</v-icon>-->
          <h5  style="text-transform: uppercase"
               :style="textColor ? `color : ${textColor}` : ''"
          >
           {{$i18n.locale}}
          </h5>
          <v-icon :color="textColor || wsDARKER" >mdi-menu-down</v-icon>
        </v-sheet>
      </template>
      <v-sheet :color="backgroundColor">
        <v-list dense :style="backgroundColor ? `background-color : ${backgroundColor}` : ''"  >

          <v-list-item-group v-model="lang"  >
            <div v-for="(item,i) in langSelect" :key="i" @click="changeLang(item.value)">
              <router-link class="noUnderline" :to="item.link" >
                <v-list-item   >
                  <v-list-item-title >
                    <h5
                      :style="textColor ? `color : ${textColor}` : ''"
                      class="text-uppercase"
                    >
                      {{item.text  }}
                    </h5>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </div>

          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-menu>


  </div>

</template>

<script>

export default {
  name: "wsLangSelect",
  components : {

  },
  props : {
    backgroundColor : {
      type : String
    },
    textColor : {
      type : String
    }

  },
  data() {
    return {
      lang : 'ua'
    }
  },
  computed: {
    routte() {
      return this.$route.path
    },
    langSelect(){
      let items = []

      this.PARAMS_languages.forEach((lang)=>{

        let path = this.$route.path

         if ( this.$i18n.locale === process.env.VUE_APP_I18N_LOCALE ) {
           if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
             path = '/' + lang +  path
           }
         } else {
           if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
             path = '/' + lang + path.substring(3)
           } else  {
             path = path.substring(3)
           }

         }



        items.push( { text : lang, value : lang, link: path })
      })
      return items
    }
  },
  methods : {
    changeLang(input) {
      this.LOCALE_CHANGE(input)
    }
  }
}
</script>

<style scoped>

</style>