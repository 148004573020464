<template>

  <div class="user_dropdown d-flex align-center font-weight-light"
       :style="dark ? '' : 'border-color : #ffffff22'">

    <v-menu offset-y z-index="9999" transition="slide-y-transition">

      <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!main_menu"
                 id="header_user_dropdown_button"
                 small class="d-flex font-weight-medium noCaps align-center"
                 :color="btnColor" text v-bind="attrs" v-on="on" >
            <span :style="`color : ${textColor || '#000000' }`"> {{ userText }}</span>
              <v-icon :color="!dashboard ? (textColor) : '#000000' "  small v-if="loggedIn" >mdi-chevron-down</v-icon>
          </v-btn>
        <v-btn v-else class="ml-3 my-1 px-3 noCaps" text rounded elevation="0" dark small color="blue darken-2" v-bind="attrs" v-on="on">
          <v-icon v-if="!loggedIn" class="mr-2" small  >mdi-login</v-icon>{{ loggedIn ? userEmail : this.$t('Login') }}
        </v-btn>

      </template>

      <v-card class="rounded-0">

        <div v-if="loggedIn === true"


             class="d-flex pa-5 align-center">

          <v-avatar    style="cursor: pointer"
              @click="$router.push(localeLink(`dashboard/${$store.state.auth.rootPlace}`))"
              size="48" key="profile_picture" >
            <v-img  :src="$store.state.auth.userImg">
              <template slot="placeholder">
                <v-img src="@/assets/placeholder/user_blank.jpg" />
              </template>
            </v-img>
          </v-avatar>

          <div class="ml-5">
            <h3   style="cursor: pointer" @click="$router.push(localeLink(`dashboard/${$store.state.auth.rootPlace}`))" class="h6 mt-5">{{ userName }}</h3>
            <h5 class="font-weight-light mb-1"   >{{ userEmail }}</h5>
            <h5  class="font-weight-light">{{ $t('LinkKey') }}: <span class="font-weight-bold">{{ userLinkKey }}</span></h5>
          </div>

        </div>

        <v-divider></v-divider>
        <v-list nav dense >
          <v-list-item-group color="primary" >
            <v-list-item v-if="!loggedIn" @click="toggleLoginMenu">
              <v-list-item-icon>
                <v-icon >mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >{{$t('Login') }}/ {{ $t('SignUp') }} </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="loggedIn" @click="$router.push(localeLink(`dashboard/${$store.state.auth.rootPlace}`))">
              <v-list-item-icon>
                <v-icon >mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >{{ $t('ClientPanel') }}</v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <!-- back to site-->
            <v-list-item id="user_dropdown_goto_public" v-if="showBackToSite" @click="$router.push(localeLink(`${$store.state.auth.rootPlace}`))">
              <v-list-item-icon>
                <v-icon >mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title >{{ $t('BackToHomePage') }} </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="loggedIn" @click="logout">
              <v-list-item-icon>
                <v-icon >mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>

                <v-list-item-title > {{ $t('Logout') }} </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-card>
    </v-menu>


  </div>

</template>

<script>
import {mapMutations,mapState} from 'vuex';
export default {
  name: "userDropdown",
  props : {
    img_size: {
      type : String,
      default: '48'
    },
    dark : Boolean,
    main_menu : {
      type : Boolean,
      default : false
    },
    dashboard : {
      type : Boolean,
      default : false
    },
    textColor : {
      type : String
    }
  },
  computed: {
    ...mapState('auth',['loggedIn','userName','userEmail' , 'userLinkKey']),
    showBackToSite(){
      return this.$route.path.includes('dashboard')
    },
    btnColor() {
      if (this.dark) {
        return 'dark'
      } else {
        return 'white'
      }

    },
    userText(){
      if ( this.loggedIn ) {
        return this.userEmail ? this.userEmail : this.userName
      }
      return 'Login'
    }
  },
  methods : {
    ...mapMutations(['toggleLoginMenu']),
    ...mapMutations('auth',['LOGOUT']),

    logout() {
      this.LOGOUT(this.$router);
    }
  }
}
</script>

<style scoped>

.user_dropdown {
  height:100%;
}

</style>