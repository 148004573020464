<template>
  <router-link
      class="pointer noUnderline"
      v-if="!!to"
      :to="to"
  >
    <slot></slot>
  </router-link>
  <a v-else-if="!!href" :href="href" :target="!newWindow ? '_self' : '_blank'"
     class="pointer noUnderline"
  >
    <slot></slot>
  </a>
  <span v-else>
     <slot></slot>
  </span>

</template>

<script>
export default {
  name: "wsLink",
  props : {
    to : {
      type : String
    },
    href : {
      type : String
    },
    newWindow : {
      type : Boolean,
      default : false
    }
  }
}
</script>

<style scoped>

</style>