<template>
  <div>
    <h2>Log file: {{ fileName }}</h2>
    <ws-text-field
        width="320px"
        v-model="searchInField"
        label="Search"
        :hide-details="false"
        class="mr-10 mt-3"
        clearable
    />

    <div style="display: flex">
      <ws-text-field
          width="320px"
          v-model="searchInFile"
          label="Search in file"
          placeholder="123"
          :hide-details="false"
          class="mr-10"
          clearable
      />
      <ws-text-field
          width="100px"
          v-model="startLine"
          label="Start line"
          placeholder="0"
          value="0"
          :hide-details="false"
          class="mr-4"
          clearable
      />
      <ws-text-field
          width="100px"
          v-model="endLine"
          label="End line"
          placeholder="15"
          value="15"
          :hide-details="false"
          clearable
      />
      <ws-button
          @click="getFile"
          class="mt-7 ml-3"
          label="Search"
      ></ws-button>
    </div>
    <h4 class="mt-3"></h4>
    <v-sheet
        dark
        class="pa-3 overflow-y-auto mb-3"
        height="600">
      <div v-for="(line, index) in logText" :key="index" v-html="formatLine(line)"/>
    </v-sheet>
    <p>Current status: <b style="color: darkgreen">Ready</b></p>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "LogReader",
  props: {
    fileName: {
      type: String,
      default: "error",
    },
    server: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      date: "",
      searchInFile: "",
      search: "",
      startLine: 0,
      endLine: 15,
      logText: [],
    }
  },
  watch: {
    fileName() {
      this.getFile();
    },
  },

  methods: {
    ...mapActions('logs', [
      'GET_LOG_TEXT',
    ]),

    async getFile() {
      if (!this.fileName) {
        this.notify("No name");
        return;
      }

      let data = await this.GET_LOG_TEXT({
        alias: this.server,
        file_name: this.fileName,
        start_line: this.startLine,
        end_line: this.endLine,
        search_in_file: this.searchInFile
      });
      if (!data) {
        return this.notify(this.$store.state.ajax.error, 'error')
      }

      this.logText = data;
    },
    formatLine(line) {
      line = `<p class="mb-2">${line}</p>`;
      return line;
    }
  },
  mounted() {
    this.getFile();
  }
}
</script>

<style scoped>

</style>