<template>
  <dash-page-new
      title="Servers"
      subtitle="Servers"
      root="Dashboard"
      icon="mdi-server-network"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #header_action>
      <ws-button
          @click="openAddServer"
          label="AddServer"
          icon="mdi-plus"
      />

    </template>

    <template #default>
      <h1>THIS IS SERVERS</h1>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Servers</h3>
        <h4 class="mt-3">List of Servers, payment periods and their status, controls, like install git project </h4>

      </v-sheet>

      <ws-data-table
          :row-action="openServerDetails"
          :items="tableServers"
          :headers="headers"
          class="mt-6"
      >
        <template #item.name="{item}">
          <router-link :to="dashboardLink(`servers/${item.name}`)" class="noUnderline">
            <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
          </router-link>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          v-model="displayAddServerDialog"
          @save="addNewServer"
          title="Add"
          save-text="Save"
          cancel-text="Cancel"
      >
        <ws-text-field
            v-model="entityData.alias"
            label="Server Name"
            placeholder="cloud.cs1.westudy.ua"
        />
        <ws-select
            class="mt-6"
            v-model="entityData.dev"
            :items="devTypes"
            label="Is dev"
            placeholder="If application located on local machine"
        />
        <ws-select
            class="mt-6"
            v-model="entityData.type"
            :items="serverTypes"
            label="Server Type"
            placeholder="Choose server type"
        />
        <ws-text-field
            v-model="entityData.url"
            label="Url"
            placeholder="cloud.cs1.westudy.ua"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.ip"
            label="Dedicated IP Address"
            placeholder="123.123.421.242"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.port"
            label="Port (Usually uses for local development, use subdomains on prod)"
            placeholder="1234"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_cores"
            label="CPU Cores"
            placeholder="24"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_critical_temp"
            label="Critical CPU temperature"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.cpu_critical_avg"
            label="Critical CPU AVG (Usually same as core count)"
            placeholder="24"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.hdd_critical_load"
            label="Critical HDD load (%)"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.ram_critical_load"
            label="Critical RAM load (%)"
            placeholder="70"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.token"
            label="Health token"
            placeholder="G0394JG3049GJ3049GJ0432GJ304HJ35"
        />
        <ws-text-field
            class="mt-6"
            v-model="entityData.business_alias"
            label="To whom belongs"
            placeholder="coco_style"
        />
      </ws-dialog>
    </template>
  </dash-page-new>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "DashServers",
  data() {
    return {
      entityData: {},
      displayAddServerDialog: false,
      tableServers: [],
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Type', value: 'type'},
      ],
      serverTypes: [
        {text: 'api', value: 'api'},
        {text: 'cloud', value: 'cloud'},
        {text: 'health', value: 'health'},
      ],
      devTypes: [
        {text: 'Yes', value: 1},
        {text: 'No', value: 0},
      ],
      displayCard: true,
    }
  },
  methods: {
    ...mapActions("servers", [
      'ADD_SERVER',
      "GET_SERVERS",
    ]),
    async addNewServer() {
      let result = await this.ADD_SERVER(this.entityData);
      if (!result) {
        return this.notify(this.$store.state.ajax.error + 'i am herer', 'error')
      }

      this.tableServers.push({
        name: result.alias,
        type: result.type,
      });

      this.notify('New server created : ' + this.entityData.alias, "success");

      this.displayAddServerDialog = false
    },
    openAddServer() {
      this.entityData = {}
      this.displayAddServerDialog = true
    },
    openServerDetails(item) {
      this.$router.push(this.dashboardLink(`servers/${item.name}`))
    },
    async initData() {
      console.log('[DashServers:initData]')
      let data = await this.GET_SERVERS();
      if (!data) {
        return this.notify(this.$store.state.ajax.error, "error");
      }

      Object.keys(data).forEach(server => {
        this.tableServers.push({
          name: data[server].alias,
          type: data[server].type,
        });
      })
    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>