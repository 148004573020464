<template>
  <div>
    <h5 v-if="label" style="color : #4F5A6E; font-size: 13px; font-weight: 500 " class="mb-2">  {{  label  }} </h5>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-if="!customActivator"
            v-model="time"
            @focusin="focused = true"
            @blur="focused = false ; $emit('change',text)"
            :dense="dense"
            :color="!defaultStyle ? wsACCENT : ''"
            :placeholder="placeholder"
            prepend-inner-icon="mdi-calendar"
            hide-details
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
            :clearable="clearable"
            class="mt-0 "
            :class="{
              activeText : focused && !error && !defaultStyle,
              notActive : !focused && !error && !defaultStyle,
              errorStyle : error && !defaultStyle,
              textStyle : !defaultStyle
            }"/>

        <v-sheet v-if="customActivator" v-bind="attrs" v-on="on" color="transparent"  >
          <slot></slot>
        </v-sheet>
      </template>
      <v-date-picker
          light
          ref="picker"
          v-model="time"
          min="1900-01-01"
          no-title
          locale="uk-UA"
          @change="$refs.menu.save()" />

    </v-menu>
  </div>

</template>

<script>
export default {
  name: "wsDatePicker",
  props : {
    value : {
      type : [String,Number]
    },
    label : {
      type : String
    },
    placeholder : {
      type : String
    },
    color : {
      type : String
    },
    clearable : {
      type : Boolean,
      default : true
    },
    customActivator : {
      type : Boolean,
      default : false
    },
    dense : {
      type : Boolean,
      default : true
    },
    error : {
      type : Boolean,
      default : false
    },
    defaultStyle : {
      type : Boolean,
      default : false
    },
    timeStamp : {
      type : Boolean,
      default : false
    }
  } ,
  data() {
    return {
      time : '',
      focused : false,
      initReady : false,
    }
  },
  watch : {
    time(value,old) {

      if ( !this.initReady || (!old && this.timeStamp ) ) {
        return
      }

      if ( !this.timeStamp) {
          this.$emit('input',value)
      } else {
          this.$emit('input',(new Date(value).valueOf()/1000).toFixed())
      }

    },
    value() {
      this.setTime()
    }
  },
  methods : {
    setTime() {

      if (this.timeStamp) {

        var time = new Date(this.value*1000)

        if ( isNaN(time) ) {
          return
        }

        let year = time.getFullYear()
        let month = time.getMonth() + 1
        month = month < 10 ? `0${month}` : month
        let date = time.getDate()  < 10 ? ('0' +time.getDate() ) : time.getDate();

        this.time = year + '-' + month + '-' +  date


      } else {
        this.time = this.value
      }
    }
  },
  created() {
    if  (this.value ) {
      this.setTime()
      this.initReady = true
    } else {
      this.initReady = true
    }
  }
}
</script>

<style scoped>
.notActive.v-text-field--outlined >>> fieldset {
  border-color: #9AB2C4 !important;
}
.activeText.v-text-field--outlined >>> fieldset {
  border-color: #567186 !important;
  border-width: 2px;
}
.notActive.v-text-field--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-textarea--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.errorStyle.v-text-field--outlined >>> input::placeholder {
  border-color: darkred !important;
  border-width: 2px;
}
.textStyle {
  font-weight: 500;
  font-size: 14px;
}
</style>