<template>
  <v-menu  v-model="dropdown"
           :left="left"
           :disabled="disabled"
           offset-y
           :position-x="x" :position-y="y"
           :absolute="absolute"
           :open-on-hover="openOnHover"
           :close-on-content-click="closeOnClick"
           z-index="9999999999999999999999999"
           transition="slide-y-transition">
    <template v-slot:activator="{attrs,on}">
      <v-sheet  @click="activate"
                :v-ripple="noRipple"
                :class="`${fillHeight ? 'd-flex align-center fill-height' : ''} ${!disabled ? 'pointer' : ''}`"
                class="pointer d-flex align-center fill-height"
                style="cursor: pointer"
                v-bind="attrs" v-on="on"
                color="transparent" rounded  >
        <slot :text="selectedText" :expand="dropdown"  v-bind="attrs" v-on="on"  default="name">
          <wsIconButton v-if="button" :icon="button" />
        </slot>
      </v-sheet>
    </template>
    <v-sheet
        :color="backgroundColor"
        :min-height="minHeight"
        :max-height="maxHeight"
        min-width="200"
        :dark="dark"
    >


      <div v-if="searchable" class="pa-3 pb-0 mb-n6">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" :placeholder="$t('Search')" outlined dense>  </v-text-field>
      </div>

      <v-list
          v-if="itemsFiltered.length > 0"
          :style="backgroundColor ? `background-color: ${backgroundColor}` : ''"
          class="overflow-y-auto"
          :dark="dark"
          dense >
        <v-list-item-group :color="wsACCENT"  :multiple="multiple" v-model="data">
          <template  v-for="(item,i) in itemsFiltered">
            <template v-if="!item.children">
              <v-hover v-slot="{hover}" :key="i">
                <div>
                  <v-list-item
                      v-if="!item.route"
                      :value="item.value"
                      @click="action(item)"
                      class="transition-ease-in-out"
                      :style="`background-color: ${hover && !dark ? wsLIGHTCARD : ''}`"
                      :color="textColor || wsACCENT"    >

                    <v-list-item-icon  v-if="item.icon " class="px-0 mr-3 " >
                      <v-icon size="20"  :color="iconColor || wsACCENT" >{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title :style="`color : ${textColor || wsACCENT }`" >{{item.text}} <span v-if="displayValue">({{ item.value }})</span></v-list-item-title>
                  </v-list-item>
                  <a v-else-if="item.url" class="noUnderline"  :href="item.url" :key="i">
                    <v-list-item @click="action(item)" >

                      <v-list-item-icon v-if="item.icon && !left" class="px-0 mx-0" >
                        <v-icon :color="iconColor || wsACCENT" small>{{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title  :style="`color : ${textColor || wsACCENT }`" >{{ !translate ? item.text : $t(item.text)}}</v-list-item-title>
                    </v-list-item>
                  </a>
                  <router-link class="noUnderline" v-else :to="item.route" :key="i">
                    <v-list-item @click="action(item)" >

                      <v-list-item-icon v-if="item.icon && !left" class="px-0 mx-0" >
                        <v-icon  :color="iconColor || wsACCENT" small>{{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title class="pl-3"  :style="`color : ${textColor || wsACCENT }`" >{{ !translate ? item.text : $t(item.text)}}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </div>

              </v-hover>

            </template>


            <v-menu v-if="item.children"  open-on-hover :left="left"  offset-x transition="slide-x-transition" :key="i">
              <template v-slot:activator="{attrs,on}">
                <v-list-item v-bind="attrs" v-on="on"  @click="action(item)"  :key="i">

                  <v-list-item-icon v-if="item.icon && !left" class="px-0 mx-0" >
                    <v-icon :color="iconColor || wsACCENT" small>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="pl-3" :style="`color : ${!colorBlack ? wsACCENT : ''}`" >{{item.text}}</v-list-item-title>

                  <v-list-item-icon class="pl-12 pr-2 ml-9" v-if="item.children && !left">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-list-item-icon>

                </v-list-item>
              </template>

              <v-sheet max-height="290">
                <v-list dense v-if="items.length > 0">
                  <v-list-item-group >
                    <template  v-for="(childItem,j) in item.children">
                      <v-list-item v-if="!childItem.noAction"
                                   @click="action(childItem)"
                                   :style="childItem.noAction ? 'cursor : default' : ''"
                                   :key="'child_' + i + j">

                        <v-list-item-icon class="mx-0 px-0" v-if="childItem.icon ">
                          <v-icon :color="iconColor || wsACCENT" small class="mr-1">{{  childItem.icon  }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title
                            :style="`color : ${!colorBlack ? wsACCENT : ''}`" >
                          <h4 v-if="childItem.noAction">{{childItem.text}}</h4>
                          <span v-else>{{childItem.text}}</span>
                        </v-list-item-title>
                      </v-list-item>
                      <div v-else class="d-flex align-center py-1"   :key="'child_no_action' + i + j">
                        <v-sheet width="40" color="transparent">
                          <v-icon :color="iconColor || wsACCENT" small class="ml-4 mr-1">{{  childItem.icon  }}</v-icon>
                        </v-sheet>

                        <h5 v-if="childItem.noAction"
                            :style="`color : ${!colorBlack ? wsACCENT : ''}`"
                        >{{childItem.text}}
                        </h5>
                      </div>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-sheet>
            </v-menu>
          </template>
          <template  v-if="multiple && value && value.length > 0" >
            <v-btn @click="dropdown=false; $emit('input',data);"
                   :color="wsACCENT" class="noCaps"
                   block
                   text >
              {{ $t('Choose') }}
            </v-btn>
            <v-btn @click="$emit('input',[]); dropdown= false;"
                   :color="wsACCENT"
                   class="noCaps"
                   block
                   text
            >
              {{ $t('Cancel') }}
            </v-btn>
          </template>


        </v-list-item-group>
      </v-list>
      <div v-else class="px-3 py-1">
        <slot name="noItems"></slot>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: "ftSelect",
  props : {
    value : {
      type : [ String, Array , Number , Object ]
    },
    items : {
      type : Array,
      default() { return [] }
    },
    dark : {
      type : Boolean,
      default : false
    },
    button : {
      type : String,
      default: ''
    },
    fillHeight : {
      type : Boolean,
      default : false
    },
    text : {
      type : String
    },
    disabled : {
      type : Boolean,
      default: false
    },
    noRipple : {
      type : Boolean,
      default : false
    },
    left  : {
      type : Boolean,
      default : false
    },
    leftIcons : {
      type : Boolean,
      default : false
    },
    expanded : {
      type : Boolean,
      default : false
    },
    x : {
      type : Number,
      default: 0
    },
    y : {
      type : Number,
      default: 0
    },
    absolute : {
      type : Boolean,
      default : false
    },
    openOnHover : {
      type : Boolean,
      default : false
    },
    searchable : {
      type : Boolean,
      default : false,
    },
    closeOnClick : {
      type : Boolean,
      default : false
    },
    maxHeight : {
      type : String,
      default : '300',
    },
    minHeight : {
      type : String,
      default : '',
    },
    displayValue : {
      type : Boolean,
      default : false
    },
    iconColor : {
      type : String,
      default : '',
    },
    colorBlack : {
      type : Boolean,
      default : false
    },
    multiple : {
      type : Boolean,
      default : false
    },
    translate : {
      type : Boolean,
      default : false
    },
    backgroundColor : {
      type : String,
    },
    textColor : {
      type : String,
    }
  },
  data(){
    return {
      data : null,
      dropdown : false,
      search : ''
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.search ) {
        items = items.filter( el => el.text.toLowerCase().includes(this.search.toLowerCase()) )
      }

      return items
    },
    selectedText() {
      let text = ''
      if (this.value !== null && this.value !== undefined && !this.multiple) {
        text = this.getItemText(this.value)
      }
      else if ( Array.isArray(this.value)  && this.value.length > 0) {
        this.value.forEach((item,i) => {
          text += this.getItemText(item) + (i < this.value.length-1 ? ', ' : '' )
        })
      }
      return text
    }
  },
  watch : {
    // data(val) {
    //   this.$emit('input',val)
    // },
    value : {
      handler(val) {
        if ( this.data !== this.value ) {
          this.data = val
        }
      },
      deep : true
    },
    expanded(val) {
      if ( val !== this.dropdown ) {
        this.dropdown = val
      }
    },
    dropdown(value) {
      this.$emit('expand',value)
    }
  },
  methods : {
    getItemText(value) {
      let text = ''
      let item = this.items.find(el=> el.value === value)
      if ( item ) {
        text = item.text
      }
      return text
    },
    action(item) {
      if ( item.noAction ) {
        return
      }
      if (!item.children) {
        if (!this.multiple) {

          this.data = item.value;
        } else {
          if ( !Array.isArray(this.data) ) {
            this.data = []
          }
          let index = this.data.findIndex(el => el === item.value)
          if ( index === -1 ) {
            this.data.push(item.value)
          } else {
            this.data.splice(index,1)
          }
        }

       this.$emit('input',this.data)
        if ( !this.multiple ) {
          this.dropdown = false
        }

      }

      if (item.action) {
        item.action()
      }
    },
    activate() {
      this.dropdown = true;
    }
  },
  mounted() {
    if ( this.value || this.value === false  ) {
      this.data = this.value
    } else {
      this.data = this.multiple ? [] :  'Select'
    }
    if ( this.expanded ) {
      this.dropdown = true
    }
    if ( !this.data && this.multiple ) {
      this.data = []
    }

  }
}
</script>

<style scoped>

</style>