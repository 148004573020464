<template>
  <dash-page-new
      title="Backups"
      subtitle="MySql"
      root="Dashboard"
      icon="mdi-view-dashboard-outline"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #header_action>
      <ws-toggle
          v-model="displayMysql"
          text-true="MySql"
          text-false="Mongo"
      >

      </ws-toggle>
    </template>

    <template #default>

      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Backups</h3>
        <h4 class="mt-3">List of servers and backups, controll buttons to create backup - maybe restore </h4>

      </v-sheet>


      <!-- Mysql table-->
      <div v-if="displayMysql" class="mt-16">

        <h1 class="text-center" >
          Mysql Database backups and functions
        </h1>

      </div>
      <!-- Mongo table-->
      <div v-else class="mt-16">

        <h1 class="text-center" >
          MongoDB database backups and functions
        </h1>

      </div>

    </template>

  </dash-page-new>
</template>

<script>
export default {
  name: "DashBackups",
  data() {
    return {
      displayMysql : true
    }
  }
}
</script>

<style scoped>

</style>