<template>
  <v-fade-transition :style="`background-color: ${wsBACKGROUND}`" mode="out-in">

    <div v-if="loggedIn" :style="`background-color: ${wsBACKGROUND}`"   >


      <v-sheet v-if="!SM" id="dash_content"  :color="wsBACKGROUND" class=" fill-height  " >

        <v-row no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <v-col :style="sideBarStyle" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <side-bar />
          </v-col>

          <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
            <v-sheet :color="wsDASHBACKGROUND" class="fill-height">
              <router-view style="overflow-y: unset;"></router-view>
            </v-sheet>
          </v-col>

        </v-row>

      </v-sheet>
      <sideBarBusinessMobile   v-if=" SM && $store.state.dashboard.sideMenuMobile" admin />
      <div v-if="SM" class="fill-height" >

        <headerDash />

        <v-sheet  class="fill-height">
          <router-view ></router-view>
        </v-sheet>
        <footerDashMobile v-if="false" />
      </div>


    <v-dialog v-if="$store.state.dashboard.showLogoutConfirm"
              v-model="$store.state.dashboard.showLogoutConfirm"
              width="400">

      <v-sheet class="pa-4">
        <div class="d-flex justify-space-between align-center">
          <h3 class="font-weight-light">Вийти</h3>
          <v-btn @click="$store.state.dashboard.showLogoutConfirm = false"
                 small icon><v-icon small>mdi-close</v-icon></v-btn>
        </div>
        <v-divider class="mt-1" />
        <h5 class="my-12 text-center font-weight-light">Ви впевнені, що хочети вийти із вашого акаунту ?</h5>
        <div class="d-flex justify-end mb-1">
          <v-btn @click="$store.state.dashboard.showLogoutConfirm = false" outlined class="noCaps" small text>Скасувати</v-btn>
          <v-btn @click="logout" small text class="noCaps">Так</v-btn>
        </div>

      </v-sheet>

    </v-dialog>
  </div>

    <v-sheet v-else-if="!loading" :color="wsBACKGROUND" class="d-flex align-center justify-center" >
      <v-sheet class=" pa-6 px-12" :class=" displayHide(['sm']) ? 'wsRoundedHalf' : '' "
               :width="displayHide(['sm']) ? 500 : '100vh'"
               :height="displayHide(['sm']) ? '' : '100vh'">

        <h3 v-if="displayHide(['sm'])"  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h3>
        <h4 v-else  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h4>
        <auth-window-new
            no-auth-action
            display-login
            width="100%"
            class="mt-16" />
      </v-sheet>
    </v-sheet>
    <v-sheet v-else  class="d-flex align-center justify-center fill-height">
      <div class="d-block">
        <v-progress-circular size="48" :indeterminate="loading" />
        <h2 class="text-center font-weight-light">{{ $t('loading') }}</h2>
      </div>
    </v-sheet>

  </v-fade-transition>

</template>

<script>

import sideBar from "@/components/dashboard/UI/sidebar/sideBar";
import { mapState} from "vuex";
import authWindowNew from "@/components/auth/authWindowNew";
import sideBarBusinessMobile from "@/components/dashboard/UI/sidebar/sideBarBusinessMobile";
import headerDash from "@/components/dashboard/UI/headerDash";
import footerDashMobile from "@/components/dashboard/UI/footerDashMobile";
export default {
  name: 'HelloWorld',
  components: {
    sideBar,
    authWindowNew,
    sideBarBusinessMobile,
    headerDash,
    footerDashMobile,
  },
  data() {
    return {
      menuActive: false,
      loading : true,
    }
  },
  computed: {
    ...mapState('dashboard', ['sideMenu','pageTitle','breadcrumbs','modalDash']),
    ...mapState('auth', ['loggedIn']),

    sideBarStyle() {
      let width = this.sideMenu ? 280 : 75;
      return `min-width: ${width}px; max-width: ${width}px; height: 100% !important; transition : all 0.3s ease`
    },

    bodyStyleClass() {
      if ( this.sideMenu ) { return 'mainWithMenu' }
      return this.displayShow(['sm']) ? 'mainMobile' : 'main'
    }
  },
  mounted() {
      this.loading = false
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  background-color: whitesmoke;
  height:100%;
}
.main {
  margin-left: 60px;
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
  transition: all 0.3s ease-out;
}

.content {
  overflow-y: unset;
  transition: all 0.3s ease-out;
}


</style>
