<template>
  <v-row class="no-gutters flex-wrap flex-column fill-height " >

    <!-- main items -->
    <v-col cols="auto" class="grow overflow-y-auto py-0" style="height: 10px"  >
      <v-slide-x-transition mode="out-in">
        <!-- Main navigation-->
        <v-list v-if="!$store.state.dashboard.displayPageNavigation || pageNavigationFiltered.length < 2" style="background-color: transparent"
                id="business_sidebar"
                dense >

          <v-list-item-group
              :mandatory="!items_profile.map(el => el.value).includes(selectedItem)"
              :color="wsDARK" v-model="selectedItem" class="">

            <div  v-for="(section, i) in authData" :key="i" >

              <div v-for="(item, j) in section.items" :key="j"  >


                <router-link
                    :is="item.action ? 'span' : 'router-link'"
                    class="noUnderline"
                    :to="dashboardLink(item.path || '' , admin )"  >

                  <v-list-item
                      v-ripple="sideMenu"
                      :value="item.value"
                      class="px-0 mx-0"
                      :color="wsDARK"
                      @click="action(item.action)"
                      :id="item.id ? item.id : ''"  >

                    <v-list-item-icon class="mr-4 " >
                      <v-sheet :color="isSelected(item.value) ? wsACCENT : 'transparent'"
                               class="mr-5"
                               style="border-radius: 0 4px 4px 0;"
                               width="6px" />
                      <ws-tooltip :text="!sideMenu ? item.text : null">
                        <v-badge  :content="!sideMenu && item.counter ? item.counter : 0"
                                  :value="!sideMenu  && item.counter ? item.counter : 0"
                                  :color="wsATTENTION"
                                  overlap >
                          <v-icon  :color="wsACCENT"  v-text="item.icon" />
                        </v-badge>
                      </ws-tooltip>

                    </v-list-item-icon>

                    <v-list-item-content v-if="sideMenu || SM" class="ml-0 pl-0">
                      <v-list-item-title class="ml-0 pl-0"  >
                        <h4 :style="`color : ${wsACCENT}`">{{  item.text  }}</h4>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon v-if="item.counter && sideMenu" class="pr-4">
                      <v-chip v-if="item.counter > 0" small dark :color="wsACCENT"><h5>{{  item.counter  }}</h5></v-chip>
                    </v-list-item-icon>
                  </v-list-item>
                </router-link>

              </div>

            </div>
          </v-list-item-group>
        </v-list>
        <!-- Page Navigation-->
        <div v-else>
          <router-link
              v-if="$store.state.dashboard.displayPageNavigation"
              :to="dashboardLink($store.state.dashboard.pageNavigationReturnRoute, admin)"
              class="noUnderline"
          >

            <v-sheet color="transparent" height="40" v-ripple style="cursor: pointer" class="d-flex align-center" >
              <v-icon :color="wsACCENT" class="ml-6 mr-4">mdi-chevron-left</v-icon>
              <h5 v-if="sideMenu || SM"  :style="`color : ${wsACCENT}`">{{  $t('Back')  }}</h5>
            </v-sheet>
          </router-link>

          <v-list  style="background-color: transparent"
                   dense >

            <v-list-item-group
                v-model="selectedItem"
                :mandatory="!items_profile.map(el => el.value).includes(selectedItem)"
                :color="wsDARK"
                class="">


              <template v-for="(item, j) in pageNavigationFiltered">
                <div  :key="j"  >
                  <h4 :class="SM ? 'cut-text' : 'two-lines-text'" class="px-3 pl-7 mb-1 mt-2 "  v-if="item.isCategory" > {{ item.text }}</h4>
                  <h5 :class="SM ? 'cut-text' : 'two-lines-text'" class="px-3 pl-7 mb-2"  v-if="item.isSubCategory" > {{ item.text }}</h5>

                  <router-link
                      v-if="!item.isCategory && !item.isSubCategory "
                      :is="item.action ? 'span' : 'router-link'"
                      class="noUnderline"
                      :to="dashboardLink($store.state.dashboard.pageNavigationRoot + '/' + item.path, admin)"  >

                    <v-list-item
                        v-ripple="sideMenu"
                        :value="item.value"
                        class="px-0 pr-5 mx-0"
                        :color="item.iconColor || wsACCENT"
                        @click="action(item.action)"
                        :id="item.id ? item.id : ''"  >

                      <v-list-item-icon class="mr-4 " >

                        <v-sheet :color="isSelected(item.value) ? wsACCENT : 'transparent'"
                                 class="mr-5"
                                 style="border-radius: 0 4px 4px 0;"
                                 width="6px" />
                        <ws-tooltip :text="!sideMenu ? item.text : null">
                          <v-badge  :content="!sideMenu && item.counter ? item.counter : 0"
                                    :value="!sideMenu  && item.counter ? item.counter : 0"
                                    :color="wsATTENTION"
                                    overlap >
                            <v-icon  :color="wsACCENT"  v-text="item.icon" />
                          </v-badge>
                        </ws-tooltip>


                      </v-list-item-icon>

                      <v-list-item-content v-if="sideMenu || SM" class="ml-0 pl-0">
                        <v-list-item-title class="ml-0 pl-0"  >
                          <h4 class="cut-text" :style="`color : ${wsACCENT}`">{{  item.text  }}</h4>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon v-if="item.counter && sideMenu" >
                        <v-chip v-if="item.counter > 0" small dark :color="wsACCENT"><h5>{{  item.counter  }}</h5></v-chip>
                      </v-list-item-icon>

                    </v-list-item>
                  </router-link>

                </div>
              </template>


            </v-list-item-group>
          </v-list>
        </div>

      </v-slide-x-transition>
    </v-col>


    <v-col  cols="auto" class="shrink pb-5 pt-3"  >
      <v-divider class="mb-3" :style="`background-color: ${wsLIGHTCARD}`" />
      <v-list  dense   style="background-color: transparent" >
        <v-list-item-group
            :mandatory="items_profile.map(el => el.value).includes(selectedItem)"
            v-model="selectedItem"
            class="">

          <router-link
              v-for="(item, j) in items_profile" :key="j"
              :is="!item.path ? 'span' : 'router-link'"
              class="noUnderline"
              :to="dashboardLink(item.path, admin)"  >

            <v-list-item
                :value="item.value"
                class="px-0 mx-0"
                :color="wsDARK"
                @click="action(item.action)"
                :id="item.id ? item.id : ''"  >

              <v-list-item-icon class="mr-4 " >
                <v-sheet :color="isSelected(item.value) ? wsACCENT : 'transparent'"
                         class="mr-5"
                         style="border-radius: 0 4px 4px 0;"
                         width="6px" />
                <v-badge  :content="!sideMenu && item.counter ? item.counter : 0"
                          :value="!sideMenu  && item.counter ? item.counter : 0"
                          :color="wsATTENTION"
                          overlap >
                  <v-icon  :color="wsACCENT"  v-text="item.icon" />
                </v-badge>

              </v-list-item-icon>
              <v-list-item-content v-if="sideMenu || SM" class="ml-0 pl-0">
                <v-list-item-title class="ml-0 pl-0"  >
                  <h4 :style="`color : ${wsACCENT}`">{{  item.text  }}</h4>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="item.counter && sideMenu" class="pr-4">
                <v-chip v-if="item.counter > 0" small dark :color="wsACCENT"><h5>{{  item.counter  }}</h5></v-chip>
              </v-list-item-icon>


            </v-list-item>

          </router-link>

        </v-list-item-group>
      </v-list>
    </v-col>


  </v-row>
</template>

<script>

import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: "menuElementsBusiness",
  props:{
    admin : {
      type : Boolean,
      default : false,
    }
  },
  data: () => ({
    selectedItem: 0,
    businessManager : false,
    parseFromRoute : ''

  }),
  watch : {
    pageNavigation(value) {
      this.selectedItem = value
    }
  },
  computed: {
    ...mapState('cart', [ 'quantity']),
    ...mapState('dashboard', ['sideMenu','showBusinessToManager', 'pageNavigation']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('auth', ['userType']),
    ...mapState('messenger', [ 'newMessagesCount' ]),

    items() {
      return  [  {
        section: this.$t('Dashboard'),
        items: [
          {
            text: this.$t('Dashboard'),
            icon: 'mdi-view-dashboard-outline',
            value : 'dashboard',
            path: '',
          },
          {
            text: 'socket_test',
            icon: 'mdi-connection',
            value: 'socket_test',
            path: 'socket_test',
          },
          {
            text: 'Servers',
            icon: 'mdi-server-network',
            value: 'servers',
            path: 'servers',
          },
          {
            text: 'GIT',
            icon: 'mdi-git',
            value: 'git',
            path: 'git',
          },
          {
            text: this.$t('Storage'),
            icon: 'mdi-cloud-upload-outline',
            value: 'storage',
            path: 'storage',
          },
          {
            text: this.$t('Backups'),
            value: 'backups',
            icon: 'mdi-database-clock-outline',
            path: 'backups',
          },
          {
            text: this.$t('Converters'),
            value: 'converters',
            icon: 'mdi-video-image',
            path: 'converters',
          },
          {
            text: this.$t('Development'),
            value: 'development',
            icon: 'mdi-hammer-wrench',
            path: 'development',
          }
        ]
      }
      ]
    },

    pageNavigationFiltered() {
      if (this.$store.state.dashboard.pageNavigationItems.length === 0) {
        return []
      }
      let items = this.$store.state.dashboard.pageNavigationItems
      items = items.filter(el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true))
      items = items.filter( el=> !el.auth   || el.auth.includes(this.userType)  )
      return items

    },
    showToUser() {
      if (this.$store.state.dashboard.showBusinessToOwner || this.$store.state.dashboard.showBusinessToManager) {  return false }
      else { return true }
    },
    authData() {

      let items = JSON.parse(JSON.stringify(this.items))

      items = items.filter( el=> !el.auth   || el.auth.includes(this.userType)  )

      items.forEach( (section) => {
        section.items = section.items.filter( el => !el.auth   || el.auth.includes(this.userType) )

        section.items.forEach( (item) => {
          if ( item.children ) {
            item.children = item.children.filter( el => !el.auth   || el.auth.includes(this.userType) )
          }
        })
        section.items = section.items.filter( el => !el.children || el.children.length > 0 )
      })
      return items;

    },
    items_profile() {
      return [
        {
          text: this.$t('Logout'),
          icon: 'mdi-logout-variant',
          action: 'logout',
          value: 'logout'
        }
      ]
    }

  },
  methods: {
    ...mapMutations('dashboard', ['SHOW_MODAL_CONFIRM_LOGOUT', 'SET_PAGE_TITLE', 'TOGGLE_SIDEBAR']),
    //...mapActions('events', [ 'GET_RECENT_EVENTS' ]),
    ...mapActions('messenger', [ 'GET_RECENT_MESSAGES' ]),

    select(item) {

      if ( this.SM && item.messenger ) {
        this.$store.state.messenger.displayMessenger = true
        this.$store.state.dashboard.sideMenuMobile = false
        return
      }

      switch (item.action.type) {

        case 'route':
          this.SET_PAGE_TITLE(item.text);
          this.$router.push(this.dashboardLink(item.action.path, this.admin))
          break;

        case 'logout':
          this.logout()
          break;
      }

      if ( this.SM) {
        this.$store.state.dashboard.sideMenuMobile = false
      }

    },
    action(action) {
      this.$store.state.dashboard.sideMenuMobile = false
      switch ( action ) {
        case 'logout':
          this.logout()
          break;
      }
    },
    logout() {
      this.SHOW_MODAL_CONFIRM_LOGOUT();
    },

    //technical
    isSelected(value) {
      return this.selectedItem === value
    },
    checkRoute() {

      if ( this.$store.state.dashboard.displayPageNavigation ) {
        return
      }

      this.authData.forEach(section => {
        let currentRoute = section.items.find( el => el.path && this.$route.path === this.dashboardLink(el.path )  )
        if ( currentRoute ) {
          this.selectedItem = currentRoute.path
        }
      });

    }
  },
  created() {
    let route = this.$route.path.replace(this.dashboardLink('', this.admin),'')
    this.selectedItem = route ? route : 0
    this.checkRoute()
    //this.GET_RECENT_EVENTS()
    //this.GET_RECENT_MESSAGES()
  }
}
</script>

<style lang="scss" scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar{height:16px;overflow:visible;width:10px}
::-webkit-scrollbar-button{height:0;width:0}
::-webkit-scrollbar-track{background-clip:padding-box;box-shadow:inset 0px 0 0 #e6e6e6}
::-webkit-scrollbar-track:hover{background-color:transparent;}
::-webkit-scrollbar-track:active{background-color:transparent; cursor: all-scroll}
::-webkit-scrollbar-thumb{background-color:#94b3c7;min-height:28px;padding:100px 0 0;}
::-webkit-scrollbar-thumb:hover{background-color:#567186;}
::-webkit-scrollbar-thumb:active{background-color:#567186;}
</style>