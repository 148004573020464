<template>

  <div>
    <v-tooltip
        v-model="hover"
        ref="menu"
        z-index="999999999999999"
        max-width="400"
        color="transparent"
        content-class="custom-tooltip"
        :bottom="!top"
        top="top"
    >

      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-sheet class="pointer" color="transparent"  ref="activator"  v-bind="attrs"  v-on="on">
            <slot></slot>
          </v-sheet>
        </div>

      </template>

      <v-sheet v-if="hover && text"  :color="wsACCENT" style="position: relative" class="wsRoundedLight " dark >
        <div>
          <div v-if="!top"  style=" position: absolute; width: 100%; top: -8px" class="d-flex justify-center">
            <div class="d-flex justify-center mx-auto">
              <v-icon class="mx-auto" :color="wsACCENT" size="10" >mdi-triangle</v-icon>
            </div>
          </div>
          <slot name="text">
            <h5 class="pa-4" style="font-size: 12px ; line-height: 16px">
              {{ text }}
            </h5>
          </slot>

          <div v-if="top"  style=" position: absolute; width: 100%; bottom: -8px" class="d-flex justify-center">
            <div class="d-flex justify-center mx-auto">
              <v-icon class="mx-auto" :color="wsACCENT" size="10" style="transform: rotate(180deg)" >mdi-triangle</v-icon>
            </div>
          </div>
        </div>
        <slot name="content"></slot>
      </v-sheet>



    </v-tooltip>
  </div>




</template>

<script>
import {mapState} from "vuex";

export default {
  name: "wsTooltip",
  props : {
    text : {
      type : String,
      default : ''
    },
    top : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      hover : false,
    }
  },
  computed : {
    ...mapState('dashboard',['selectedTooltip'])
  },
  beforeDestroy() {

  },
}
</script>

<style scoped>
.custom-tooltip {
  opacity: 1!important;
}
</style>