<template>
  <div id="notifications" ref="notification">
    <!-- NOTIFICATION FOR CHANGE-->

    <v-slide-y-transition>
      <ws-notification
          v-if="$store.state.notification"
          :text="$store.state.notification_text"
      />
<!--      :text=""-->
    </v-slide-y-transition>




  </div>

</template>

<script>


import {mapState} from "vuex";
import wsNotification from "@/components/UI/wsNotification";

export default {
  name: "notification",
  components: {
    wsNotification,
  },

  computed : {
    ...mapState('notifications',['notifications','notify_title','notify_text']),
    ...mapState('messenger',['displayNewMessage','newMessages']),
    ...mapState('events',['newEvents'])

  },
  watch : {
  },
  methods : {
    deleteNewMessage(index) {
      this.$store.state.messenger.newMessages.splice(index,1)
    },
    deleteNewEvent(index) {
      this.$store.state.events.newEvents.splice(index,1)
    }
  },
  mounted() {
    let notifications = this.$refs.notification
    document.body.appendChild(notifications)
  }
}
</script>

<style scoped>

.d-flex {
  display : flex
}

.card {
  transition: all 0.5s;

}
.card-enter, .card-leave-to
  /* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.card-enter-to {
  opacity: 1;
  transform: scale(1);
}

.card-leave-active {
  /*position: absolute;*/
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.newMessages {
  position: fixed;
  top : 55px;
  right: 15px;
}
</style>