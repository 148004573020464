const actions = {

    async GET_ALL_SERVERS_SOCKET({dispatch} ) {
        return await dispatch('socket/EMIT', {
            action: `servers/get_all`,
        },{ root : true });
    },



    // OLD BACK FUNCTIONS

    async ADD_SERVER({dispatch}, body) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv`,
            method: 'POST',
            data: body,
        }, {root: true});
    },
    async EDIT_SERVER({dispatch}, body) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv`,
            method: 'PUT',
            data: body,
        }, {root: true});
    },

    async GET_SERVERS({dispatch}) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv`,
            method: 'GET',
        }, {root: true});
    },

    async GET_SERVERS_HEALTH({dispatch}) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/sts`,
            method: 'GET',
        }, {root: true});
    },

    async GET_SERVER_CONFIG({dispatch}, serverAlias) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv/` + serverAlias,
            method: 'GET',
        }, {root: true});
    },

    async GET_NON_BUSINESS_CLOUD_SERVERS({dispatch}) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv?only_cloud&non_client`,
            method: 'GET',
        }, {root: true});
    },

    async EXECUTE_FILE_RELOCATION({dispatch}, body) {
        return await dispatch('ajax/SEND_NEW', {
            route: `ajx/srv/rlc`,
            method: 'POST',
            data: body,
        }, {root: true});
    },
}

export default {
    namespaced: true,
    actions
}
