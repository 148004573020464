<template>
  <div   @click="dropdown = true" :style="!SM ? 'width: 250px' : null" :class="SM ? 'mr-n1 mt-2' : null">

    <v-menu  v-model="dropdown"
             :close-on-content-click="false"
             offset-y
             z-index="9999999999999999999999999"
    >
      <template v-slot:activator="{}">
        <ws-text-field
            v-model="filters.search"
            @input="$emit('input',filters)"
            :placeholder="$t('SearchAndFilters')"
            @focus="focused = true"
            @blur="focused = false"
            clearable
            icon="mdi-magnify"
        />
      </template>
      <v-sheet :min-width="!SM ? 400 : null"  class="pa-6" >

        <div class="d-flex justify-space-between align-center mb-4">
          <h4 >
            <v-icon :color="wsACCENT">mdi-tune-vertical</v-icon>
            {{ $t('Filters') }}
          </h4>
          <v-btn @click="dropdown = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-simple-table :style="`border: 0.5px solid ${wsBACKGROUND}`" >
          <tbody>

          <tr v-for="item in items"
              style="background-color: transparent"
              :key="item.value"
          >
            <td :style="`border-color:  ${wsBACKGROUND}`">

              <ft-select
                  v-if="item.type === 'select' || item.type === 'select_multiple'"
                  v-model="filters[item.value]"
                  @input="$emit('input',filters)"
                  :multiple=" item.type === 'select_multiple'"
                  :searchable="item.items.length > 7"
                  :items="item.items">
                <template #default="{text}">
                  <v-sheet color="transparent" class="d-flex align-center justify-space-between">
                    <h5 v-if="item.type !== 'select_multiple'" :style="`color : ${filters[item.value] !== null && filters[item.value] !== undefined  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>
                    <h5 v-else :style="`color : ${ Array.isArray(filters[item.value])  && filters[item.value].length > 0  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>

                    <div class="d-flex align-center">
                      <v-btn
                          v-if="filters[item.value] !== null && filters[item.value] !== undefined"
                          @click.stop="filters[item.value] = null"
                          icon small>
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>

                      <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                    </div>

                  </v-sheet>
                </template>

              </ft-select>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
    </v-menu>


  </div>

</template>

<script>
export default {
  name: "wsSearchFilters",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array,
      default() { return [] }
    }
  },
  watch : {
    value() {
      if ( this.value !== this.filters ) {
        this.filters = JSON.parse(JSON.stringify(this.value))
      }
    }
  },
  data() {
    return {
      focused : false,
      dropdown : false,
      filters : {}
    }
  },
  mounted() {
    if ( this.value !== this.filters ) {
      this.filters = JSON.parse(JSON.stringify(this.value))
    }
    if ( Object.keys(this.filters).length === 0 ) {
      this.items.forEach(item => {
        this.filters[item.value] = item.type === 'select_multiple' ? [] : null
      })
      this.filters = JSON.parse(JSON.stringify(this.filters))
    }
  }
}
</script>

<style scoped>

</style>