const state = {
    error : null
}
const actions = {

    async SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async SEND_NEW({rootState,commit, state}, body) {
        state.error = null
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            if (body.returnFile) {
                params['returnFile'] = true
            }

            if ( process.env.VUE_APP_MODE === 'dev' ) {
                commit('debug/DEBUG_AJAX' , params, { root: true } )
            }

            const data = await rootState.$ajax_new(params);

            if ( process.env.VUE_APP_MODE === 'dev' ) {
                commit('debug/DEBUG_AJAX_SUCCESS' , data, { root: true } )
            }

            rootState.LOADING = false;
            return data


        } catch (e) {

            if ( process.env.VUE_APP_MODE === 'dev' ) {
                commit('debug/DEBUG_AJAX_ERROR' , e.message, { root: true } )
            }

            if ( e.message === 'maintenance_mode_on' ) {
                rootState.MAINTENANCE_ON = true
            } else {
                rootState.MAINTENANCE_ON = false
            }

            if (e.message === 'refresh_expired') {
                rootState.LOADING = false;
                rootState.notification = true
                rootState.notification_text = 'Session expired'
               return commit('auth/LOGOUT', null, { root: true })
            }

            state.error = e.message
            // rootState.notification = true
            // rootState.notification_text = e

            rootState.LOADING = false;
            return e.error
        }
    },

}
export default {
    namespaced:true,
    state,
    actions

}