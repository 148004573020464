<template>
  <div class="py-2"  >

    <wsVideo
        v-if="section.isUploaded"
        :file="section.file"
        :courseId="courseId"
        :testId="testId"
        :student="student"
        :key="componentId + index"
        :isPublic="isPublic"
    />

    <wsEmbed
        v-if="section.isEmbed"
        :src="section.embed.url" />

  </div>
</template>

<script>

export default {
  name: "editorSectionVideo",
  props : {
    value : {
      type : Object
    },
    section : {
      type : Object,
      default() { return {} }
    },
    student : {
      type : Boolean,
      default : false,
    },
    courseId : {
      type : String,
    },
    componentId : {
      type : String
    },
    index : {
      type : Number
    },
    testId : {
      type : String
    },
    isPublic : {
      type : Boolean,
      default : false,
    }

  },
  data() {
    return {
      videoType : 'upload',
      displayEdit : true,
    }
  }
}
</script>

<style scoped>

</style>