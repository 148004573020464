import Vue from "vue";
import Router from "vue-router";

import localeRouterView from "@/localeRouterView";
import Dashboard from "@/components/dashboard/PageDashboard";
import StatesTable from "@/components/dashboard/states/StatesTable";
import Page404 from "@/components/Page404";
import LoginPage from "@/components/auth/LoginPage";
import DashServers from "@/components/dashboard/servers/DashServers";
import DashStorage from "@/components/dashboard/storage/DashStorage";
import DashConverters from "@/components/dashboard/converters/DashConverters";
import DashBackups from "@/components/dashboard/backups/DashBackups";
import DashDevelopment from "@/components/dashboard/development/DashDevelopment";
import DashGit from "@/components/dashboard/git/DashGit";
import serverDetails from "@/components/dashboard/servers/serverDetails";
import serverStates from "@/components/dashboard/states/serverStates";
import DashSocketTest from "../components/dashboard/socket_test/DashSocketTest.vue";

Vue.use(Router);

const routes = [
    {   path: "dashboard",
        component: Dashboard,
        children : [

            { path: ""                 , component: StatesTable } ,
            { path: "socket_test"      , component: DashSocketTest } ,
            { path: "states/:server"   , component: serverStates , props : true } ,
            { path: "servers"          , component: DashServers } ,
            { path: "servers/:server"  , component: serverDetails , props : true } ,
            { path: "git"              , component: DashGit } ,
            { path: "storage"          , component: DashStorage } ,
            { path: "converters"       , component: DashConverters } ,
            { path: "backups"          , component: DashBackups } ,
            { path: "development"      , component: DashDevelopment }

        ]
    },
    { path: '', component: LoginPage },
    { path: "*", component: Page404 }
]


function localeRoutes() {
    return [
        {
            path: '/:lang([A-z]{2})?',
            component: localeRouterView,
            props: true,
            children: routes
        },
    ]
}

export default function getRouter() {

    return new Router({
        mode: process.env.NODE_ENV === 'production' ? 'history' : 'history',
        routes: localeRoutes(),
        linkActiveClass: null,
    })
}
