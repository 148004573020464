<template>
  <v-app >
    <portal-target name="dialog"></portal-target>
    <notification></notification>
    <v-sheet v-if="LOADING" color="transparent" style="position: fixed; top :0 ; left: 0 ; right: 0; height: 10px; z-index: 99999999999999">
      <v-progress-linear  indeterminate :color="wsACCENT" height="5" />
    </v-sheet>
    <span v-show="false" id="virtual_hidden"></span>

    <router-view :key="$store.state.auth.spectatorTrigger"></router-view>
  </v-app>

</template>

<script>

import {mapActions} from "vuex";
import notification from "@/components/UI/notification";

export default {
  name: 'App',
  title : 'Fine Trading',
  components: {
    notification,
  },
  methods: {

    ...mapActions('auth',['GET_USER']),
    ...mapActions('socket' , ['INIT_SOCKET']),

    shiftDown(e){
      if ( e.key === "Shift" ) {
        this.$store.state.shiftDown = true
      }
    },
    shiftUp(e){
      if ( e.key === "Shift" ) {
        this.$store.state.shiftDown = false
      }
    },

  },
  async created() {

    window.addEventListener('keydown', this.shiftDown )
    window.addEventListener('keyup', this.shiftUp )

    this.INIT_SOCKET({socket : this.$socket, sockets : this.sockets})
    this.$store.state.FRONT_LOCATION = window.location.host;

    await this.CHECK_INVITATION_CODE()

    if (!this.$store.state.auth.loggedIn) {
      if (localStorage.getItem('access_token')){
        this.$store.state.auth.accessToken = localStorage.getItem('access_token')

        this.GET_USER( {
          token: localStorage.getItem('access_token') ,
          router : {
            router :this.$router,
            path   :this.$route.path
          },
          redirect : false,
        } )
      }
    }


  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;1,300&display=swap');
@import '~shepherd.js/dist/css/shepherd.css';


h1,h2,h3,h4,h5,h6 {
  font-family: "AmbulatoriaD" ;
  font-weight: 600;
}

#app {
  font-weight: 300;
  background-color: #242424;
  font-family: "AmbulatoriaD" ;
}

.nunito {
  font-family: "Nunito";
}
//.nunito {
//  h1,h2,h3,h4,h5,h6 {
//    font-family: "Nunito";
//  }
//}

.fill-height {
  height: 100% !important;
  min-height: 100% !important;
}
.grow {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
}
.shrink {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}



.noUnderline {
  text-decoration: none;
}

.wsHoverLight tbody tr:hover {
  background-color : #F1F8FB !important
}
.wsHoverLightTr:hover {
  background-color : #F1F8FB !important
}
.wsRoundedLight {
  border-radius: 4px !important;
}
.wsRoundedLightLeft {
  border-radius: 8px 0 0 8px;
}
.wsRoundedLightRight {
  border-radius: 0 8px 8px 0;
}
.wsRoundedLightTopSemi {
  border-radius: 8px 8px 0 0;
}
.wsRoundedLightBottom {
  border-radius: 0 0 4px 4px;
}
.wsRounded  {
  border-radius: 30px !important;
}
.wsRoundedTop  {
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
}
.wsRoundedHalfTop {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.wsRoundedLeft {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.wsRoundedLeft {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.wsRoundedHalf  {
  border-radius: 15px !important;
}
.wsRoundedHalf-2  {
  border-radius: 20px !important;
}

.wsRoundedHalfBottom  {
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.wsRoundedHalfRight {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.wsRoundedHalfLeft {
  border-radius: 16px 0 0 16px;
}

.wsRoundedHalfTopRight {
  border-top-right-radius: 15px !important;
}
.wsRoundedHalfTopLeft {
  border-top-left-radius: 15px !important;
}

.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}

.pointer {
  cursor: pointer !important;
}

.noCaps {
  text-transform: none !important;
  letter-spacing: unset !important;
  font-weight: 600 !important;
}

.compact-form {
  transform: scale(0.875);
  transform-origin: center;
}
.finetTable  {
table {
  border-radius: 0 !important;
}
  thead {
    border-radius: 0 !important;
    tr {
      border-radius: 0 !important;
      td {
        border-radius: 0 !important;
      }
    }
    border-radius: 0 !important;
    td {
      border-radius: 0 !important;
    }
  }
td {
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #393939;
}
tr { transition: all .3s ease; }
tr:hover {
  background-color: #459ad2 !important;
  color:white !important;
  border-radius: 0;
  cursor : pointer;
  i{
    color:white !important;
    transition: all .3s ease;
  }
td { color: white;
div span {
  color: white !important;
  border-color : white ! important;
  transition: all .3s ease;
}
}
}




}
.fineBorder {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle {
  font-size: 12px;
  font-weight: 400;
}
.toggle_item {
  border : 2px solid #dddddd;
  border-radius : 5px;
  transition: all .3s ease;
}
.toggle_item_active {
  background-color: #585858;
  border : 2px solid #585858;
  color : white;
}
.toggle_item:hover {
  background-color: #585858;
  border : 2px solid #585858;
  color : white !important;
  cursor: pointer;
}
.editWindow {
  border : 1px #ffffffbb dashed;
}
.editWindow:hover {
  background-color: #ffffff55;
  cursor: pointer;
}
.linkHover:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.hover-underline:hover {
  text-decoration: underline !important;
}

.editorStyle {
  outline: none !important;
  resize: none !important;
}
.editorStyle:read-only {
  outline: none !important;
  resize: none !important;
}
.readOnlyCursor:read-only {
  cursor: default;
}


.wsInput input::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.wsInput {
  font-size: 14px !important;
  font-weight: 400 !important;
}
*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



</style>
