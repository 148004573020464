<template>
  <dash-page-new
      title="Converters"
      subtitle="MySql"
      root="Dashboard"
      icon="mdi-view-dashboard-outline"
      :no-content-condition="$store.state.auth.userType !== 'superadmin'"
  >

    <template #default>

      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="pa-4 mt-6 wsRoundedHalf"
      >
        <h3>Converters</h3>
        <h4 class="mt-3">I want to see list of converters of selected server and contorl them - for example disable certeain converter , or disable all </h4>

      </v-sheet>

    </template>

  </dash-page-new>
</template>

<script>
export default {
  name: "DashConverters"
}
</script>

<style scoped>

</style>