<template>
  <div :style="`width : ${width}`">

    <div v-if="label" class="d-flex justify-space-between align-center mb-2">
      <h5 style="color : #4F5A6E; font-size: 13px; font-weight: 500 " >  {{  label  }} </h5>
      <ws-tooltip v-if="tooltip" :text="tooltip">
        <v-icon :color="wsDARKLIGHT">mdi-information-outline</v-icon>
      </ws-tooltip>
    </div>

    <v-select
        v-if="!autocomplete"
        v-model="text"
        :items="items"
        @keypress="handleKeyPress( $event,text )"
        @focusin="focused = true"
        @blur="focused = false ; $emit('change',text)"
        :class="{
          activeText : focused && !error,
          notActive : !focused && !error,
          errorStyle : error
        }"
        class="textStyle mt-0"
        :placeholder="!text ? placeholder : ''"
        :color="wsACCENT"
        :type="type"
        dense
        outlined
        :hide-details="hideDetails"
        :error="error"
        :error-messages="errorMessages"
        :height="height"
        :counter="counter"
        :clearable="clearable"
        :prepend-inner-icon="prependInnerIcon"
        :disabled="disabled"
        :multiple="multiple"
        :background-color="backgroundColor || null"

    >
      <template #item="{item}">
        <v-icon v-if="item.icon"  class="mr-3"> {{ item.icon }}</v-icon>
        <h5>{{ item.text }}</h5>
      </template>
    </v-select>


    <v-autocomplete
        v-else
        v-model="text"
        :items="items"
        @keypress="handleKeyPress( $event,text )"
        @focusin="focused = true"
        @blur="focused = false ; $emit('change',text)"
        :class="{
          activeText : focused && !error,
          notActive : !focused && !error,
          errorStyle : error
        }"
        class="textStyle mt-0"
        :placeholder="placeholder"
        :color="wsACCENT"
        :type="type"
        dense
        outlined
        :hide-details="!counter"
        :error="error"
        :height="height"
        :counter="counter"
        :clearable="clearable"
        :multiple="multiple"
    />



  </div>
</template>

<script>
export default {
  name: "wsTextField",
  props : {
    value : {
      type : [ String , Array ]
    },
    items : {
      type : Array,
      default() { return [] }
    },
    placeholder : {
      type : String,
    },
    label : {
      type : String,
    },
    tooltip : {
      type : String,
    },
    number : {
      type : Boolean,
      default : false
    },
    numberLength : {
      type : String,
      default : '10000000',
    },
    error : {
      type : Boolean,
      default : false
    },
    errorMessages : {
      type : [String, Array]
    },
    autocomplete : {
      type : Boolean,
      default : false
    },
    counter : {
      type : String,
    },
    height : {
      type : String,
    },
    clearable : {
      type : Boolean,
      default : false
    },
    width : {
      type :String,
      default : '100%'
    },
    prependInnerIcon : {
      type :String,
    },
    disabled : {
      type : Boolean,
      default : false
    },
    multiple : {
      type : Boolean,
      default : false
    },
    backgroundColor : {
      type :String,
    },
    hideDetails : {
      type : Boolean,
      default : false
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.value !== this.text ) {
        this.$emit('input',this.text)
      }
    }
  },
  data() {
    return {
      text : '',
      focused : false
    }
  },
  computed : {
    type() {
      if ( this.number ) {
        return 'number'
      }
      return 'text'
    }
  },
  methods : {
    handleKeyPress($event , element ) {
      if ( this.number ) {
        this.NUMBER_INPUT($event,element, parseInt(this.numberLength) )
      }
    }
  },
  mounted() {
    this.text = this.value
  }
}
</script>

<style lang="css" scoped>
/*.v-text-field--outlined >>> fieldset {*/
/*  border-color: rgba(192, 0, 250, 0.986);*/
/*}*/
.notActive.v-text-field--outlined >>> fieldset {
  border-color: #9AB2C4 !important;
}
.activeText.v-text-field--outlined >>> fieldset {
  border-color: #567186 !important;
  border-width: 2px;
}
.notActive.v-text-field--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.errorStyle.v-text-field--outlined >>> input::placeholder {
  border-color: darkred !important;
  border-width: 2px;
}
.textStyle {
  font-weight: 500;
  font-size: 14px;
}



</style>