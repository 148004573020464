const state = {

    socialsAuthUrlGoogle: null,
    socialsAuthUrlFacebook: null,

    isSpectator: false,
    spectatorTrigger: 0,
    spectatorReturnRoute: '',
    accessToken: '',
    refreshToken: '',
    resendToken: '',
    loggedIn: false,


    showNewUserDialog: false,

    modalAuth: false,
    modalType: '',

    userType: null,
    userID: null,
    user: {
        businesses: [],
        managingBusinesses: [],
        locations: [],
        roles: []
    },
    userLang: null,
    userRentedProducts: null,
    userEmail: '',
    userName: '',
    userBusinesses: [],
    userWarehouses: [],
    userManagingBusinesses: [],
    businessManagerPermissions: {},
    userLinkKey: '',
    userImg: '',

    changePasswordSent: false,
    showChangePansword: false,
    changePasswordMailReady: false,
    changePasswordMailResult: false,

    rootPlace: ''

}
const getters = {
    isLoggedIn(state) {
        return state.loggedIn
    },
}
const mutations = {
    TOOGLE_LOADER(state) {
        state.loader = !state.loader;
    },
    SET_TOKEN(state, data) {

        state.loggedIn = true;
        state.accessToken = data.access

        localStorage.setItem('access_token', data.token)

    },
    SET_RESEND_TOKEN(state, payload) {
        state.resendToken = payload.token
    },

    TOGGLE_AUTH_MODAL(state) {
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRMAION(state) {
        state.modalType = 'AUTH_REGISTER_CONFIRM'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRM_INFO(state) {
        state.modalType = 'AUTH_CONFIRM_INFO'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_PASSWORD_RESET(state) {
        state.modalType = 'AUTH_PASSWORD_RESET'
        state.modalAuth = !state.modalAuth;
    },
    SET_EMAIL_RESULT(state, payload) {
        state.changePasswordMailResult = payload.result;
    },
    TOGGLE_EMAIL_RESULT_READY(state) {
        state.changePasswordMailReady = !state.changePasswordMailReady
    },
    FLUSH_CHANGE_PASSWORD(state) {
        state.changePasswordSent = false;
        state.showChangePansword = false;
        state.changePasswordMailReady = false;
        state.changePasswordMailResult = false;
        state.loader = false;
    },
    LOGOUT(state) {

        this.dispatch('auth/UNSUBSCRIBE_SOCKET')

        state.userID = null;
        state.authToken = null;
        state.refreshToken = null;
        state.userEmail = null;
        state.userName = null;
        state.loggedIn = false;
        state.userEmail = ''
        state.userLinkKey = ''
        state.userLang = null
        state.userType = null
        state.userID = null
        state.userRentedProducts = null
        state.userBusinesses = []
        state.userWarehouses = []
        state.userManagingBusinesses = []
        state.businessManagerPermissions = {}
        state.userImg = ''

        //     this.dispatch('cart/CLEAR_CART', null, {root:true})
        //      this.dispatch('business/RESET_BUSINESS_STATE')

        state.user = {
            businesses: [],
            managingBusinesses: [],
            locations: []
        }
        localStorage.clear();


    }
}
const actions = {

    UNSUBSCRIBE_SOCKET({rootState, state}) {
        rootState.$socket.emit('unsubscribe', {
            id: state.userID,
            businesses: state.userBusinesses,
            warehouses: state.userWarehouses,
            subscriptions: []
        })
    },

    async LOGIN({commit, dispatch}, body) {

        let data = await dispatch('socket/EMIT', {
            action: `auth/by_credentials`,
            data: body
        }, {root: true});

        if (!data) {
            return
        }

        commit('SET_TOKEN', data)
        return await dispatch('GET_USER', data.access)

    },


    async GET_USER({dispatch}, body) {

        let data = await dispatch('socket/EMIT', {
                action: `user/get`,
                data: {
                    token: body
                }
            }, {root: true}
        );

        if (!data) {
            return false
        }
        dispatch('SET_USER', data);

        return true

    },

    async CHANGE_PASSWORD({dispatch, commit}, body) {

        let data = await dispatch('ajax/SEND_NEW', {
            route: `user/reset/confirm`,
            method: 'POST',
            data: body
        }, {root: true});

        if (!data) {
            return false
        }
        commit('SET_TOKEN', data)
        return await dispatch('GET_USER', data.access)

    },

    SET_USER({state}, data) {
        state.user = data
        state.loggedIn = true;
    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}